<script setup>
import { Overview } from '@/components/library/';
import { Thread } from '@/models'
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api'
import { onMounted } from 'vue'

const repo = useAxiosRepo(Thread).setAxios(api)

onMounted(async () => {
    await repo.api().get('/threads/', { params: { limit: 1000 } })
})
</script>

<template>
    <div class="col-span-8 justify-center">
        <div class="flex flex-col items-center">
            <div class="max-w-5xl w-full">
                <Overview />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useParalegalManager } from '@/composables/paralegal';
import { ParalegalForm } from '@/components/paralegals/';
import { toast } from 'vue-sonner';
import { useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();
const { newParalegal, copyParalegal, saveParalegal } = useParalegalManager();

const paralegal = ref(null);

onMounted(async () => {
  if (route.query.copy) {
    paralegal.value = copyParalegal(route.query.copy);
    paralegal.value.copied_from_id = route.query.copy
  } else {
    paralegal.value = await newParalegal("search");
  }
});

const handleSubmit = async (newParalegal) => {
  try {
    await saveParalegal(newParalegal);
    router.push({ name: 'paralegals' });
    toast.success('De paralegal is succesvol aangemaakt.');
  } catch (error) {
    toast.error('Het aanmaken van de paralegal is niet gelukt.');
  }
};
</script>

<template>
  
  <ParalegalForm v-if="paralegal" :mode="route.query.copy ? 'copy' : 'new'" :paralegal="paralegal" @submit="handleSubmit" />

  <div v-else>Loading...</div>
</template>
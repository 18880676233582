<script setup>
import { Separator } from '@/components/ui/separator'
import { defineProps, toRefs, defineModel, inject } from 'vue'
import { usePosthog } from '@/composables/analytics';
const { identifyUser } = usePosthog();
const props = defineProps(['sendMessage', 'suggestions'])
const { sendMessage, suggestions } = toRefs(props)
const text = defineModel()
const posthog = inject('posthog');
const handleSuggestionClick = async (suggestion) => {
  text.value = suggestion
  identifyUser()
  posthog.capture('$user_selected_suggestion', { suggestion: suggestion })
  await sendMessage.value()
}

</script>

<template>
    <div v-if="suggestions?.length" class="ml-[2px]">
        <Separator class="mt-4 mb-2" />
        <div class="flex flex-col" v-for="(suggestion, i) in suggestions" :key="i">
            <div @click="handleSuggestionClick(suggestion)"
                class="flex flex-row items-center h-[40px] cursor-pointer hover:text-secondary my-4">
                <svg width="25" height="25" class="mr-4 bg-transparent min-w-[25px] min-h-[25px]" viewBox="0 0 15 15"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                        fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
                <h1 class="mr-4 text-left font-semibold text-sm hover:text-secondary">{{ suggestion }}</h1>
            </div>
            <Separator class="mt-2 mb-2" />
        </div>
    </div>
</template>
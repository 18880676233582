<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator, BreadcrumbList } from '@/components/ui/breadcrumb';
import { ChevronRight } from 'lucide-vue-next';
import { NewParalegal, ViewParalegal } from '@/components/paralegals/';

const route = useRoute();
const router = useRouter();

const isNewParalegal = computed(() => route.name === 'newParalegal');

const paralegalProps = computed(() => {
  if (isNewParalegal.value) {
    return { copy: route.query.copy };
  }
  return { id: route.params.id };
});
</script>

<template>
  <div class="">
    <div class="flex flex-col h-full items-center">
      <div class="max-w-5xl w-full h-full">
        <div class="flex flex-col h-full w-full">
        <div class="flex flex-col h-full mx-6 my-4">
          <div class="mb-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink class="cursor-pointer hover:text-secondary text-muted-foreground/80"
                    @click="router.push('/')">
                    Home
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator>
                  <ChevronRight class="h-4 w-4" />
                </BreadcrumbSeparator>
                <BreadcrumbItem>
                  <BreadcrumbLink class="cursor-pointer hover:text-secondary text-muted-foreground/80"
                    @click="router.push('/paralegals')">
                    Paralegals
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator>
                  <ChevronRight class="h-4 w-4" />
                </BreadcrumbSeparator>
                <BreadcrumbItem>
                  <BreadcrumbLink class="text-primary/80">
                    Instellingen
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <NewParalegal class="flex flex-col h-full" v-if="isNewParalegal" v-bind="paralegalProps" />
          <ViewParalegal v-else class="flex flex-col h-full" v-bind="paralegalProps" />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineModel, onBeforeMount } from 'vue';
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Paralegal } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useForm } from 'vee-validate';
import { toast } from 'vue-sonner';
import { AlertDialogAction } from '@/components/ui/alert-dialog';
import { ParalegalSelector } from '@/components/searchbar';
import { api } from '@/plugins/api'

const user = defineModel();
const repo = useAxiosRepo(Paralegal)

if (!repo.find(user.value.settings.default_paralegal)) {
    user.value.settings.default_paralegal = repo.where("name", "Algemeen").first().id
}
const { handleSubmit } = useForm({
    initialValues: user.value.settings,
})
const onSubmit = handleSubmit(async (values) => {
    try {
        //user.value.settings.custom_instructions = values.custom_instructions
        user.value.settings.default_paralegal = values.default_paralegal
        user.value = (await api.put(`/users/${user.value.id}`, { settings: user.value.settings })).data
        toast.success('Je profiel is opgeslagen');
    } catch (error) {
        toast.error('Er is iets misgegaan bij het opslaan van je profiel');
    }
})

onBeforeMount(() => {
    if (!repo.find(user.value.settings.default_paralegal)) {
        user.value.settings.default_paralegal = repo.where("name", "Algemeen").first().id
    }
})
</script>

<template>
    <form class="grid grid-cols-1 gap-x-4 gap-y-2 text-left" @submit="onSubmit">

        <!-- <FormField v-slot="{ componentField }" name="custom_instructions" class="col-span-2">
            <FormItem>
                <FormLabel>Custom instructies</FormLabel>
                <FormControl>
                    <Textarea class="border-border border rounded-md h-28" type="text"
                        placeholder="Vertel Zeno wat over jezelf zodat dit meegenomen wordt in de output."
                        v-bind="componentField" />
                </FormControl>
                <FormDescription>
                </FormDescription>
                <FormMessage />
            </FormItem>
        </FormField> -->
        <FormField v-slot="{ componentField }" name="default_paralegal" class="col-span-2">
            <FormItem>

                <div class="flex flex-col space-y-3">
                    <FormLabel>Standaard paralegal</FormLabel>
                    <FormControl>
                        <ParalegalSelector class="w-full" v-model="user.settings.default_paralegal"
                            v-bind="componentField" />
                    </FormControl>
                </div>
                <FormDescription>
                    Kies de paralegal die standaard wordt gebruikt voor nieuwe zoekopdrachten.
                </FormDescription>
                <FormMessage />
            </FormItem>
        </FormField>

        <div class="flex flex-row space-x-2 pt-2">
            <AlertDialogAction asChild>
                <Button type="submit">
                    Opslaan
                </Button>
            </AlertDialogAction>

        </div>
    </form>
</template>
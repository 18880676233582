import { Thread } from '@/models/Thread';
import { Model } from 'pinia-orm'

class Paralegal extends Model {
    static entity = 'paralegals'
    static fields() {
        return {
            id: this.attr(null),
            type: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            copied_from_id: this.attr(null),
            name: this.string(''),
            description: this.string(''),
            plan: this.string('pro'),
            custom: this.boolean(true),
            settings: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            threads: this.hasMany(Thread, 'paralegal_id')
        }
    }
    get schema() {
        let schema = { ...this.settings.json_schema };
        // Resolve all $defs in the schema
        if (schema && schema.$defs) {
            const resolveRefs = (obj) => {
                for (const key in obj) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        if (obj[key].allOf && obj[key].allOf.length === 1) {
                            obj[key] = obj[key].allOf[0];
                        }
                        if (obj[key].$ref && obj[key].$ref.startsWith('#/$defs/')) {
                            const refKey = obj[key].$ref.split('/').pop();
                            obj[key] = { ...schema.$defs[refKey], ...obj[key] };
                            delete obj[key].$ref;
                        }
                        resolveRefs(obj[key]);
                    }
                }
            };

            resolveRefs(schema);
            delete schema.$defs;
        }

        return schema;
    }
}

export { Paralegal }

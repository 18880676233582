<script setup>
import ParalegalSelector from '@/components/searchbar/ParalegalSelector.vue';
import { onMounted, defineProps, toRefs, ref, inject } from 'vue';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useSearch } from '@/composables';
import Upgrade from '@/components/Upgrade';
import { usePosthog } from '@/composables/analytics';
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const props = defineProps(['ctx', 'id'])
const { ctx, id } = toRefs(props)
const { paralegalId, text, sendMessage } = useSearch(ctx.value, id.value)
const open = ref(false)


async function send() {
    try {
        await sendMessage();
        
    } catch (e) {
        identifyUser()
        posthog.capture('$user_out_of_credits', { thread_id: id?.value })
        open.value = true;
    }
}

function handleEnter(event) {
    if (!event.shiftKey) {
        event.preventDefault();
        send();
    }
}


onMounted(() => { document.getElementById('searchbar-large-textarea')?.focus() })

</script>

<template>
    <div class="p-3 w-full flex flex-col items-center bg-backgroundSecondary border  mt-6 mb-2 rounded-lg"
        style="width: 101%;">
        <div class="mx-4 h-full w-full border rounded-lg flex-grow">
            <Textarea class="min-h-[100px] rounded-t-lg dark:bg-backgroundSecondary bg-white text-primary/90 dark:text-primary large-textarea-search-ref" id="searchbar-large-textarea" v-model="text"
                @keydown.enter="handleEnter" />
            <div class="flex flex-row items-center justify-between rounded-b-lg h-full p-2 dark:bg-backgroundSecondary bg-white w-full">
                <div v-if="ctx !== 'thread'" class="flex flex-row items-center space-x-1 select-paralegal-ref">
                    <ParalegalSelector v-model="paralegalId" />
                </div>
                <Button @click="send" variant="default" class="h-full items-center">
                    <i class='bx bxs-send'></i>
                </Button>
                
            </div>
        </div>
    </div>
    <p class="text-muted-foreground mx-2 text-xs mb-4">
        Zeno kan fouten maken. Verifieer daarom altijd belangrijke informatie. Antwoorden van Zeno zijn informatief en niet bedoeld als juridisch advies.
    </p>
    <Upgrade :share="true" v-model="open" :no_button="true" />
</template>

<style scoped>
#searchbar-large-textarea {
    resize: none;
}
</style>
<script setup>
import { SourceReference } from '@/components/sources'
import { Skeleton } from '@/components/ui/skeleton'
import { defineProps, toRefs, h } from 'vue'
import { marked } from 'marked';
const props = defineProps(['show', 'answer', 'sources'])
const { show, answer, sources } = toRefs(props)

function replacementsFor(answer) {
    let replacements = {}
    if (!answer) return replacements
    const matches = answer.matchAll(/\[(.+?)\]/g)
    for (let match of matches) {
        const fullMatch = match[0]
        const innerContent = match[1]
        let [type, id] = innerContent.split('#', 2)
        type = type.toLowerCase()

        const i = sources.value[type]?.findIndex(s => s.metadata.parent_id === id)
        if (i >= 0) {
            const src = sources.value[type][i]
            replacements[fullMatch] = h(SourceReference, { i: i, src: src })
        }
    }

    return replacements
}

function parseWithComponents(inputString, replacements) {
    const tokens = marked.lexer(inputString)

    function processTextWithReplacements(text) {
        const parts = text.split(/(\[.+?\])/)
        return parts.map(part => replacements[part] || part)
    }
    
    function processInlineElements(tokens) {
        return tokens.flatMap(token => {

            if (token.type === 'text') {
                return processTextWithReplacements(token.raw)
            } else if (token.type === 'strong') {
                return h('strong', { class: `strong` }, processInlineElements(token.tokens))
            } else if (token.type === 'em') {
                return h('em', {}, processInlineElements(token.tokens))
            } else if (token.type === 'codespan') {
                return h('code', {}, token.raw)
            } else if (token.type === 'link') {
                return h('a', { href: token.href}, processInlineElements(token.tokens))
            } else {
                // For any other inline elements, process them as text
                return processTextWithReplacements(marked.parser([token]))
            }
        })
    }

    function processToken(token) {
        if (token.type === 'paragraph') {
            return h('p', {class: 'text-p-answer'}, processInlineElements(token.tokens))
        } else if (token.type === 'heading') {
            return h(`h${token.depth}`, { class: `text-${token.depth}-answer` }, processInlineElements(token.tokens))
        } else if (token.type === 'space') {
            return '\n'
        } else if (token.type === 'code') {
            return h('pre', {}, [h('code', {}, token.raw)])
        } else if (token.type === 'list') {
            const listItems = token.items.map(item => {
                const processedItem = [...processToken(item)];
                
                // Parse the text
                let text = processedItem.map(el => typeof el === 'string' ? el : el.children).flat().join(' ');
                text = text.replace(/([a-zA-Z]+)([A-Z])/g, '$1: $2')
                           .replace(/([a-zA-Z]+):([A-Z])/g, '$1: $2')
                           .replace(/\b([A-Z]+):\s*([A-Z])\b/g, '$1$2');
                
                return h('li', { class: 'flex mb-2' }, [
                    h('span', { class: 'text-muted-foreground mr-1' }, [
                        token.ordered
                            ? h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, '•')
                            : h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, '•')
                    ]),
                    h('span', {class: 'text-list-answer'}, text)
                ])
            })
            return h(token.ordered ? 'ol' : 'ul', { class: 'list-none pl-0' }, listItems)
        } else if (token.type === 'list_item') {
            return token.tokens.flatMap(processToken)
        } else {
            // For other block-level elements, we'll render as HTML and then process for replacements
            const renderedHtml = marked.parser([token])
            const div = document.createElement('div')
            div.innerHTML = renderedHtml
            return processTextWithReplacements(div.textContent)
        }
    }

    return tokens.flatMap(processToken)
}


const render = () => {
    if (!answer.value) return null
    const replacements = replacementsFor(answer.value)
    return h('div', { class: "prose text-start" }, parseWithComponents(answer.value, replacements))
}
</script>

<template>
    <div>
        <h4 class="scroll-m-20 text-left text-xl font-semibold justify-start flex items-center pb-2">
            <svg class="mr-2" width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 4.5C2 4.22386 2.22386 4 2.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H2.5C2.22386 5 2 4.77614 2 4.5ZM2 7.5C2 7.22386 2.22386 7 2.5 7H7.5C7.77614 7 8 7.22386 8 7.5C8 7.77614 7.77614 8 7.5 8H2.5C2.22386 8 2 7.77614 2 7.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H10.5C10.7761 10 11 10.2239 11 10.5C11 10.7761 10.7761 11 10.5 11H2.5C2.22386 11 2 10.7761 2 10.5Z"
                    fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
            Antwoord
        </h4>
        <div class="ml-1" v-if="show">
            <component :is="render" />
        </div>
        <div v-else>
            <Skeleton class="h-5 my-2 w-[500px] rounded-full" />
            <Skeleton class="h-5 my-2 w-[300px] rounded-full" />
            <Skeleton class="h-5 my-2 w-[400px] rounded-full" />
        </div>
    </div>
</template>

<style>
.text-3-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.5rem;
    font-weight: 400;
}
.text-4-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.6rem;
    font-weight: 400;
}

.text-4-answer .strong {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.5rem;
    font-weight: 400;
}

.text-p-answer {
    font-size: 0.98rem;
    padding-bottom: 0.50rem;
}

.strong {
    font-weight: 400;
}
</style>
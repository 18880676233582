<script setup>
import { Button } from '@/components/ui/button';
import { MenuIcon} from 'lucide-vue-next';
import { useSidebarStore } from '@/stores/sidebar';
import { Feedback, Settings } from '@/components/topmenu/areas';
import Upgrade from '@/components/Upgrade.vue';
import { Sparkles } from 'lucide-vue-next';
import { useSubscriptionStore } from '@/stores/subscription';
import { HelpButton } from '@/components/help';
import { ThemeToggle } from '@/components/settings';
const sidebarStore = useSidebarStore();
const subscription = useSubscriptionStore();

</script>

<template>
  <nav class="flex justify-between py-2 items-center px-4 border-b w-full">
    <div class="flex" >
      <Button v-if="!sidebarStore.isSidebarOpen" variant="outline" @click="sidebarStore.toggleSidebar">
        <MenuIcon class="w-5 h-5" />
      </Button>
    </div>
    <div class="flex items-center space-x-2">
      <Upgrade v-if="subscription.plan === 'free'" size="sm" text="Upgrade" :icon="Sparkles" />
      <Feedback />
      <HelpButton />
      <Settings />
      <ThemeToggle />
    </div>
  </nav>
</template>
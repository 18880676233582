import { createPiniaOrmAxios } from '@pinia-orm/axios';
import { createORM } from 'pinia-orm';
import { createPinia } from 'pinia';
import { api } from './api';

const axiosPlugin = createPiniaOrmAxios({ axios: api });
const piniaOrm = createORM({ plugins: [axiosPlugin] });
const pinia = createPinia();
pinia.use(piniaOrm);

export default pinia;
// useThread.js
import { ref, onMounted, onUnmounted } from 'vue';
import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread, ModelRun } from '@/models';
import { api } from '@/plugins/api';
import { useSocketStore } from '@/stores/socket';

const threadRepo = useAxiosRepo(Thread).setAxios(api)
const runRepo = useAxiosRepo(ModelRun).setAxios(api)

export function useThread(id) {
    const { threadSocket } = useSocketStore();
    const thread = ref(null)
    onMounted(async () => {
        await runRepo.api().get(`/threads/${id}/runs/`)
        thread.value = threadRepo.withAllRecursive().find(id)
        threadSocket.emit('enter_thread', { thread_id: id })
    })

    onUnmounted(() => {
        threadSocket.emit('leave_thread', { thread_id: id })
    })

    return { thread }
}

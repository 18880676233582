import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useAuth } from '@/auth';
import { api } from '@/plugins/api';

export const useOnboardingStore = defineStore('onboarding', () => {
  const auth = useAuth();
  const is_onboarded = ref(false);

  watch(() => auth.user, (newUser) => {
    if (newUser) {
      is_onboarded.value = newUser.settings?.is_onboarded || false;
    }
  }, { immediate: true });

  async function updateOnboarding(newOnboarding) {
    is_onboarded.value = newOnboarding;
    if (auth.user?.id) {
      try {
        await api.put(`/users/${auth.user.id}`, { settings: { is_onboarded: newOnboarding } });
        console.log('Onboarding updated successfully to ', newOnboarding);
      } catch (error) {
        console.error('Error updating onboarding:', error);
      }
    } else {
      console.log('User not authenticated, onboarding not saved');
    }
  }

  async function completeOnboarding() {
    await updateOnboarding(true);
  }

  return {
    is_onboarded,
    updateOnboarding,
    completeOnboarding
  };
});
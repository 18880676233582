<script setup>
import { computed, defineProps, ref } from 'vue';
import { Threads } from '@/components/sidebar/areas'
import NewThreadButton from '@/components/threads/New';
import { Button } from '@/components/ui/button';
import { useRouter } from 'vue-router';
import { cn } from '@/lib/utils';
import { useSidebarStore } from '@/stores/sidebar';
import { useMediaQuery } from '@vueuse/core';
import { Subscription } from '@/components/sidebar/areas';
import { SidebarLink } from '@/components/sidebar/areas';

const router = useRouter();
const props = defineProps({
  class: { type: String, default: '' },
});
const sidebarStore = useSidebarStore();
const rootClasses = computed(() => props.class);


const isTablet = useMediaQuery('(max-width: 1024px)');
const isHovering = ref(false);

const showCollapseButton = computed(() => isTablet.value || isHovering.value);

function handleMouseEnter() {
  isHovering.value = true;
}

function handleMouseLeave() {
  isHovering.value = false;
}
</script>

<template>
  <div :class="rootClasses"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div :class="cn('flex flex-col justify-between')">
      <div class="flex flex-col">
        <div class="w-full flex flex-row items-center h-[60px] justify-between px-2">

            <div 
            @click="router.push({ name: 'home' })" 
            class="cursor-pointer w-[5rem] h-full ml-3 bg-no-repeat bg-contain bg-center"
            :style="{ backgroundImage: 'var(--logo)' }"
          ></div>
          
          <Button  v-if="showCollapseButton" class="transition-opacity duration-300" variant="ghost" size="icon" @click="sidebarStore.toggleSidebar" >
            
            <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.85355C7.04882 3.65829 7.04882 3.34171 6.85355 3.14645C6.65829 2.95118 6.34171 2.95118 6.14645 3.14645L2.14645 7.14645C1.95118 7.34171 1.95118 7.65829 2.14645 7.85355L6.14645 11.8536C6.34171 12.0488 6.65829 12.0488 6.85355 11.8536C7.04882 11.6583 7.04882 11.3417 6.85355 11.1464L3.20711 7.5L6.85355 3.85355ZM12.8536 3.85355C13.0488 3.65829 13.0488 3.34171 12.8536 3.14645C12.6583 2.95118 12.3417 2.95118 12.1464 3.14645L8.14645 7.14645C7.95118 7.34171 7.95118 7.65829 8.14645 7.85355L12.1464 11.8536C12.3417 12.0488 12.6583 12.0488 12.8536 11.8536C13.0488 11.6583 13.0488 11.3417 12.8536 11.1464L9.20711 7.5L12.8536 3.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
          </Button>
        </div>
      </div>
      <NewThreadButton class="px-6 pb-3 new-thread-button-ref" />
      <SidebarLink routeName="paralegals" buttonText="Paralegals" class="paralegal-sidebar-ref">
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bot-message-square"><path d="M12 6V2H8"/><path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"/><path d="M2 12h2"/><path d="M9 11v2"/><path d="M15 11v2"/><path d="M20 12h2"/></svg>
        </template>
      </SidebarLink>
      <SidebarLink routeName="library" buttonText="Bibliotheek" class="library-sidebar-ref">
        <template #icon>
          <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.75432 0.819537C7.59742 0.726821 7.4025 0.726821 7.24559 0.819537L1.74559 4.06954C1.59336 4.15949 1.49996 4.32317 1.49996 4.5C1.49996 4.67683 1.59336 4.84051 1.74559 4.93046L7.24559 8.18046C7.4025 8.27318 7.59742 8.27318 7.75432 8.18046L13.2543 4.93046C13.4066 4.84051 13.5 4.67683 13.5 4.5C13.5 4.32317 13.4066 4.15949 13.2543 4.06954L7.75432 0.819537ZM7.49996 7.16923L2.9828 4.5L7.49996 1.83077L12.0171 4.5L7.49996 7.16923ZM1.5695 7.49564C1.70998 7.2579 2.01659 7.17906 2.25432 7.31954L7.49996 10.4192L12.7456 7.31954C12.9833 7.17906 13.2899 7.2579 13.4304 7.49564C13.5709 7.73337 13.4921 8.03998 13.2543 8.18046L7.75432 11.4305C7.59742 11.5232 7.4025 11.5232 7.24559 11.4305L1.74559 8.18046C1.50786 8.03998 1.42901 7.73337 1.5695 7.49564ZM1.56949 10.4956C1.70998 10.2579 2.01658 10.1791 2.25432 10.3195L7.49996 13.4192L12.7456 10.3195C12.9833 10.1791 13.2899 10.2579 13.4304 10.4956C13.5709 10.7334 13.4921 11.04 13.2543 11.1805L7.75432 14.4305C7.59742 14.5232 7.4025 14.5232 7.24559 14.4305L1.74559 11.1805C1.50785 11.04 1.42901 10.7334 1.56949 10.4956Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
        </template>
      </SidebarLink>
      <Threads/>
      <Subscription class="subscription-sidebar-ref" />
    </div>
  </div>
</template>
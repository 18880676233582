<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, inject } from 'vue';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { ToggleGroupField } from '@/components/paralegals';
import { useRouter } from 'vue-router';
import { Trash2, BookOpen } from 'lucide-vue-next';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { SearchBar } from '@/components/searchbar';
import { Separator } from '@/components/ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Toggle } from '@/components/ui/toggle'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useParalegalManager } from '@/composables';
import { useTour } from '@/composables/tutorial';
import { usePosthog } from '@/composables/analytics';
import { useAuth } from '@/auth';
const { identifyUser } = usePosthog();
const auth = useAuth();
const subscription = computed(() => auth.user.subscription.plan)
const posthog = inject('posthog');
const router = useRouter();
const manager = useParalegalManager()

const props = defineProps(['paralegal', 'mode']);
const emit = defineEmits(['submit', 'delete', 'copy']);

const formRef = ref(null);

const paralegal = ref(props.paralegal);
const schema = ref(paralegal.value.schema)

const handleStartTour = () => {
  const routeName = router.currentRoute.value.name;
  
  const { startTour } = useTour();
  startTour('newParalegal');

  
  identifyUser();
  posthog.capture('$user_started_tutorial', { for_route: routeName });
};

const area_of_law_categories = ref({})


onMounted(async () => {
  if (!schema.value) {
    schema.value = await manager.getSchema("search")
  }
  let categories = {}
  for (const [category, area] of schema.value?.properties.search.properties.areas_of_law.items.enum || []) {
    if (categories[category]) {
      categories[category].push(area)
    } else {
      categories[category] = [area]
    }
  }
  area_of_law_categories.value = categories
  for (const category in categories) {
    if (!chosen_areas.value[category]) chosen_areas.value[category] = []

  }
})

const handleSubmit = () => {
  if (formRef.value && formRef.value.checkValidity()) {
    if (!paralegal.value.settings?.search) {
      paralegal.value.settings.search = {}
    }
    let areas_of_law = []
    for (const [cat, areas] of Object.entries(chosen_areas.value)) {
      for (const area of areas) {
        areas_of_law.push([cat, area])
      }
    }
    paralegal.value.settings.search.areas_of_law = areas_of_law
    paralegal.value.settings.search.cases.court_category = chosen_court_categories.value
    paralegal.value.settings.search.regulations.regulation_type = chosen_regulation_types.value
    emit('submit', paralegal.value);
  } else {
    formRef.value.reportValidity();
  }
};

const chosen_court_categories = ref([])
for (const cat of paralegal.value.settings?.search.cases.court_category || []) {
  chosen_court_categories.value.push(cat)
}

const chosen_regulation_types = ref([])
for (const reg of paralegal.value.settings?.search.regulations.regulation_type || []) {
  chosen_regulation_types.value.push(reg)
}

const chosen_areas = ref({})
for (const [category, area] of paralegal.value.settings?.search.areas_of_law || []) {
  if (chosen_areas.value[category]) {
    chosen_areas.value[category].push(area)
  } else {
    chosen_areas.value[category] = [area]
  }
}

const toggleAllAreas = (category) => {
  if (isAllAreasSelected(category)) {
    chosen_areas.value[category] = [];
  } else {
    chosen_areas.value[category] = [...area_of_law_categories.value[category]];
  }
};

const isAllAreasSelected = (category) => {
  return chosen_areas.value[category]?.length === area_of_law_categories.value[category]?.length;
};

const toggleAllCourts = () => {
  if (isAllCourtsSelected()) {
    chosen_court_categories.value = [];
  } else {
    chosen_court_categories.value = [...schema.value.properties.search.properties.cases.properties.court_category.items.enum];
  }
};

const isAllCourtsSelected = () => {
  return chosen_court_categories.value.length === schema.value.properties.search.properties.cases.properties.court_category.items.enum.length;
};

const areaSearchQuery = ref('');

const filteredAreas = computed(() => {
  return Object.keys(area_of_law_categories.value).reduce((acc, category) => {
    if (!areaSearchQuery.value) {
      acc[category] = area_of_law_categories.value[category] || [];
    } else {
      acc[category] = (area_of_law_categories.value[category] || []).filter(
        area => area.toLowerCase().includes(areaSearchQuery.value.toLowerCase())
      );
    }
    return acc;
  }, {});
});

const courtSearchQuery = ref('');
const filteredCourtCategories = computed(() => {
  if (!courtSearchQuery.value) {
    return schema.value?.properties.search.properties.cases.properties.court_category.items.enum || [];
  }
  return (schema.value?.properties.search.properties.cases.properties.court_category.items.enum || []).filter(
    category => category.toLowerCase().includes(courtSearchQuery.value.toLowerCase())
  );
});


const toggleAllRegulationTypes = () => {
  if (isAllRegulationTypesSelected()) {
    chosen_regulation_types.value = [];
  } else {
    chosen_regulation_types.value = [...schema.value.properties.search.properties.regulations.properties.regulation_type.items.enum];
  }
};

const isAllRegulationTypesSelected = () => {
  return chosen_regulation_types.value.length === schema.value.properties.search.properties.regulations.properties.regulation_type.items.enum.length;
};

const regulationSearchQuery = ref('');
const filteredRegulationTypes = computed(() => {
  if (!regulationSearchQuery.value) {
    return schema.value?.properties.search.properties.regulations.properties.regulation_type.items.enum || [];
  }
  return (schema.value?.properties.search.properties.regulations.properties.regulation_type.items.enum || []).filter(
    type => type.toLowerCase().includes(regulationSearchQuery.value.toLowerCase())
  );
});

const isUsable = computed(() => {
    return subscription.value === 'pro'
});
const isMobile = ref(false);

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768; // Adjust this value as needed
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});


</script>
<template>
<div class="flex max-h-[750px] flex-col h-full">
  <form ref="formRef" @submit.prevent="handleSubmit" class="space-y-4 h-full text-left flex-grow flex flex-col">
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row space-x-2 items-center">
        <h4 class="scroll-m-20 text-left text-xl font-bold justify-start flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"
            class="mr-2 lucide lucide-bot-message-square">
            <path d="M12 6V2H8" />
            <path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
            <path d="M2 12h2" />
            <path d="M9 11v2" />
            <path d="M15 11v2" />
            <path d="M20 12h2" />
          </svg>
          Paralegal instellingen
        </h4>
      </div>
      <div v-if="isUsable">
        <div v-if="props.mode === 'view' || props.mode === 'copy'"
          class="flex flex-row justify-start space-x-2 items-center">
          <Button v-if="paralegal.custom" type="submit">Opslaan</Button>
          <Button v-else variant="default" @click.prevent="$emit('copy')">Maak een kopie</Button>
          <Button v-if="paralegal.id && paralegal.custom && props.mode === 'view'" @click="$emit('delete')" type="button"
            variant="outline">
            <Trash2 class="w-4 h-4" />
          </Button>
          <Button v-if="props.mode === 'copy'" @click="router.push({ name: 'paralegals' })">Annuleren</Button>
        </div>
      </div>
      <div v-if="props.mode === 'new'" class="flex flex-row justify-start space-x-2 items-center">
        <div v-if="isUsable">
          <Button type="submit">Opslaan</Button>
          <Button v-if="paralegal.id" variant="outline" @click="router.push({ name: 'paralegals' })">Annuleren</Button>
        </div>
        <div v-else>
          <Button variant="outline" disabled class="border border-secondary bg-secondary/10 px-1 font-semibold rounded">
                <p class="text-secondary text-xs">Alleen voor PRO gebruikers</p>
          </Button>
        </div>
      </div>
    </div>

    <div class=" flex flex-col h-full grid border border-border rounded-lg grid-cols-3 relative">
      <Button @click.prevent="handleStartTour" variant="outline" size="icon" class="border border-secondary bg-secondary/40 hover:bg-secondary/60 absolute top-4 right-4 rounded-full z-10">
        <h4 class="text-xl text-secondary-foreground font-bold">?</h4>
      </Button>

      <div class="flex flex-col col-span-1 space-y-4 border-r bg-backgroundSecondary border-border rounded-tl-lg rounded-bl-lg p-4 paralegal_settings_sidebar">
        <FormField v-slot="{ componentField }" name="name" class="flex-1">
          <FormItem>
            <div class="flex flex-col items-center pb-4 ">
              <div class="p-2 border bg-background border-border rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-bot-message-square">
                  <path d="M12 6V2H8" />
                  <path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
                  <path d="M2 12h2" />
                  <path d="M9 11v2" />
                  <path d="M15 11v2" />
                  <path d="M20 12h2" />
                </svg>
              </div>
            </div>
            <FormLabel class="text-sm font-regular">Naam</FormLabel>
            <FormControl>
              <Input type="text" id="name" v-model="paralegal.name" :default-value="paralegal.name" placeholder="Naam"
                v-bind="componentField" :disabled="!paralegal.custom" required />
            </FormControl>
          </FormItem>
        </FormField>
        <Separator class="my-4" />
        <div class="flex flex-col">
          <div class="flex flex-col space-y-1">
            <h4 class="text-sm mb-2 font-semibold text-muted-foreground">Samenvatting</h4>
            <div class="flex flex-col ">
              <ScrollArea class="rounded-lg h-[40dvh] sm:h-[35dvh] overflow-hidden">
                <div class="pr-2">
                  <Accordion type="single" collapsible>
                    <AccordionItem value="item-1">
                      <AccordionTrigger>
                        <p class="text-sm text-muted-foreground">Rechtsgebieden</p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <template v-if="Object.values(chosen_areas).some(areas => areas.length > 0)">
                          <div v-for="(areas, category) in chosen_areas" :key="category" class="mb-2 ">
                            <div v-if="areas.length > 0" class="grid grid-cols-1 sm:grid-cols-2 gap-1">
                              <div v-for="area in areas" :key="area" class="col-span-1 flex flex-col py-2 px-2 border-border border bg-accent rounded-lg">
                                <div class="flex flex-row space-x-1 items-center max-w-[140px] justify-between">
                                  <p class="text-xs items-center text-wrap">
                                    {{ area }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-xs text-muted-foreground">Geen rechtsgebieden geselecteerd</p>
                        </template>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-2">
                      <AccordionTrigger>
                        <p class="text-sm text-muted-foreground">Uitspraken</p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <template v-if="Object.values(chosen_court_categories).some(chosen_court_categories => chosen_court_categories.length > 0)">
                          <div class="grid grid-cols-1 sm:grid-cols-2 gap-1 mb-2">
                          
                            
                            <div v-for="cat in chosen_court_categories" :key="cat" class="col-span-1 flex flex-col py-2 px-2 border-border border bg-accent rounded-lg">
                              <div class="flex flex-row space-x-1 items-center max-w-[140px] justify-between">
                                <p class="text-xs items-center text-wrap">
                                  {{ cat }}
                                </p>
                              </div>
                            </div>
                            
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-xs text-muted-foreground">Geen instanties geselecteerd</p>
                        </template>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-3">
                      <AccordionTrigger>
                        <p class="text-sm text-muted-foreground">Regelingen</p>
                      </AccordionTrigger>
                      <AccordionContent>
                        <template v-if="Object.values(chosen_regulation_types).some(chosen_regulation_types => chosen_regulation_types.length > 0)">
                          <div class="grid grid-cols-1 sm:grid-cols-2 gap-1 mb-2">
                          
                            
                            <div v-for="reg in chosen_regulation_types" :key="reg" class="col-span-1 flex flex-col py-2 px-2 border-border border bg-accent rounded-lg">
                              <div class="flex flex-row space-x-1 items-center max-w-[140px] justify-between">
                                <p class="text-xs items-center text-wrap">
                                  {{ reg }}
                                </p>
                              </div>
                            </div>
                            
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-xs text-muted-foreground">Geen regelingen geselecteerd</p>
                        </template>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>
      </div>

      <Tabs class="flex flex-col col-span-2 w-full h-full" default-value="areas_of_law" orientation="vertical">
        
        <div class="flex flex-col items-center py-4 space-y-2">
          <div class="p-2 border bg-backgroundSecondary border-border rounded-lg">
            <BookOpen class="w-6 h-6 " />
          </div>
        </div>

        <TabsList v-if="isMobile" class="flex flex-col space-y-1 border border-border rounded-lg bg-background p-2 paralegal_source_categories h-full">
          <TabsTrigger value="areas_of_law" class="py-2 w-full text-left rounded-lg hover:bg-backgroundSecondary">
            🇳🇱 Rechtsgebieden
          </TabsTrigger>
          <TabsTrigger value="court_categories" class="py-2 w-full text-left rounded-lg hover:bg-backgroundSecondary">
            🇳🇱 Uitspraken
          </TabsTrigger>
          <TabsTrigger value="regulation_types" class="py-2 w-full text-left rounded-lg hover:bg-backgroundSecondary">
            🇳🇱 Regelingen
          </TabsTrigger>
          <TabsTrigger value="documents" disabled class="py-2 w-full text-left rounded-lg hover:bg-backgroundSecondary">
            Docs
          </TabsTrigger>
        </TabsList>
        <TabsList v-else class="mx-auto my-auto space-x-1 border border-border h-12 rounded-lg bg-background px-3 py-2 rounded-full paralegal_source_categories">
          <TabsTrigger value="areas_of_law" class="py-1 w-full rounded-full hover:bg-backgroundSecondary">
            🇳🇱 Rechtsgebieden
          </TabsTrigger>
          <TabsTrigger value="court_categories" class="py-1 w-full rounded-full hover:bg-backgroundSecondary">
            🇳🇱 Uitspraken
          </TabsTrigger>
          <TabsTrigger value="regulation_types" class="py-1 w-full rounded-full hover:bg-backgroundSecondary">
            🇳🇱 Regelingen
          </TabsTrigger>
          <TabsTrigger value="documents" disabled class="py-1 w-full rounded-full hover:bg-backgroundSecondary">
            Docs
          </TabsTrigger>
        </TabsList>
        <Separator class="w-full mt-4" />
        <TabsContent value="areas_of_law" class="paralegal_source_selection">
          <Tabs default-value="Strafrecht" orientation="vertical"
            class="flex grid-cols-6 w-full h-full">
            <div class="flex flex-col sm:flex-row grid-cols-6 h-full w-full">
              <div class="flex flex-col col-span-6 sm:col-span-2 h-full border-r p-2 border-border">
                <div class="w-full">
                  <TabsList
                    class="flex flex-col space-y-3 p-2 h-full w-full justify-center bg-background items-center border-border border rounded-lg">

                    <TabsTrigger v-for="category in Object.keys(area_of_law_categories)" :asChild="true" :key="category"
                      :value="category" class="justify-start w-full h-9 text-left rounded-lg hover:bg-backgroundSecondary">
                      <span class=" text-ellipsis  whitespace-nowrap ">
                        {{ category }}
                      </span>
                    </TabsTrigger>
                  </TabsList>
                </div>
              </div>
              <TabsContent v-for="category in Object.keys(area_of_law_categories)" :key="category" :value="category" class="w-full h-full col-span-6 sm:col-span-4 transition-all duration-300">      
                <div class="flex flex-col h-full w-full col-span-4">
                  <div class="flex flex-row justify-between p-2 items-center">
                    <Toggle @click="toggleAllAreas(category)"  :disabled="!paralegal.custom" :pressed="isAllAreasSelected(category)" size="sm" class="border border-border py-1 px-2 text-muted-foreground mr-2">
                      <p class="text-sm">Neem alles mee</p>
                    </Toggle>   
                    <Input 
                      v-model="areaSearchQuery"
                      placeholder="Zoek..."
                      class="sm:w-48 w-full h-8"
                    />
                  </div>
                  <Separator />
                  <div class="flex-grow overflow-hidden flex-col w-full">
                    <ScrollArea class="h-full pb-2 w-full h-[15dvh] sm:h-[39dvh]">
                      <div class="px-2">
                        <ToggleGroupField 
                          v-if="paralegal.settings && paralegal.type" 
                          v-model="chosen_areas[category]"
                          :options="filteredAreas[category]" 
                          :disabled="!paralegal.custom" 
                          :label="category" 
                          :cols="isMobile ? 1 : 2" />
                      </div>
                    </ScrollArea>
                  </div> 
                </div>
              </TabsContent>
            </div>
          </Tabs>
        </TabsContent>
        <TabsContent value="court_categories" class="flex flex-col transition-all duration-300">
          <div class="flex flex-col w-full h-full">
            <div class="flex flex-row justify-between items-center p-2">
              <div class="flex items-center">
                <Toggle 
                  @click="toggleAllCourts"  
                  :disabled="!paralegal.custom" 
                  :pressed="isAllCourtsSelected()" 
                  size="sm" 
                  class="border border-border py-1 px-2 text-muted-foreground mr-2"
                >
                  <p class="text-sm">
                    Neem alles mee</p>
                </Toggle>
              </div>
              <div class="flex items-center">
                <Input 
                  v-model="courtSearchQuery"
                  placeholder="Zoek..."
                  class="sm:w-48 w-full h-8"
                />
              </div>
            </div>
     
              <Separator />

              <div class="flex-grow overflow-hidden flex-col">
                <ScrollArea class="h-full h-[22dvh] sm:h-[39dvh] pb-2">
                  <div class="px-2">
                    <ToggleGroupField 
                      v-if="paralegal.settings && paralegal.type" 
                      v-model="chosen_court_categories"
                      :options="filteredCourtCategories"
                      :disabled="!paralegal.custom" 
                      label="court_categories"  
                      :cols="isMobile ? 1 : 3" 
                    />
                  </div>
                </ScrollArea>
              </div>
          </div>
        </TabsContent>
        <TabsContent value="regulation_types" class="flex flex-col h-full transition-all duration-300">
          <div class="flex flex-col w-full h-full">
            <div class="flex flex-row justify-between items-center p-2">
              <div class="flex items-center">
                <Toggle 
                  @click="toggleAllRegulationTypes" 
                  :pressed="isAllRegulationTypesSelected()" 
                  :disabled="!paralegal.custom" 
                  size="sm" 
                  class="border border-border py-1 px-2 text-muted-foreground mr-2"
                >
                <p class="text-sm">Neem alles mee</p>
                </Toggle>
              </div>
              <div class="flex items-center ">
                <Input 
                  v-model="regulationSearchQuery"
                  placeholder="Zoek..."
                  class="sm:w-48 w-full h-8"
                />
              </div>
            </div>

            <Separator/>

            <div class="flex-grow overflow-hidden flex-col">
              <ScrollArea class="h-full h-[22dvh] sm:h-[39dvh] pb-2">
                <div class="px-2">
                <ToggleGroupField 
                  v-if="paralegal.settings && paralegal.type" 
                  v-model="chosen_regulation_types"
                  :options="filteredRegulationTypes"
                  :disabled="!paralegal.custom"
                  :cols="isMobile ? 1 : 3" 
                  label="regulation_types"
                />
                </div>       
              </ScrollArea>
            </div>
          </div>
        </TabsContent>
      </Tabs>

    </div>
  </form>
  <div v-if="paralegal.id" class="pt-4">
    <SearchBar size="sm" ctx="paralegal" :id="paralegal.id" />
  </div>
  <div v-else class="pt-4">
    <SearchBar size="sm" ctx="new_paralegal" id="" />
  </div>
</div>
</template>
<script setup>
import { HoverCard, HoverCardTrigger, HoverCardContent } from '@/components/ui/hover-card'
import { getSourceIcon, getSourceLink } from '@/lib/sources'
import { Badge } from '@/components/ui/badge'
import { defineProps, toRefs } from 'vue'
import { ScrollArea } from '@/components/ui/scroll-area'

const props = defineProps(['i', 'src'])
const { i, src } = toRefs(props)
</script>

<template>
<HoverCard asChild>
    <HoverCardTrigger asChild>
    <a :href="getSourceLink(src)" target="_blank">
    <Badge variant="outline" class="cursor-pointer hover:bg-accent">
        <img :src="getSourceIcon(src)" class="h-3 w-3 mr-1" />
        <p> {{ i + 1 }}</p>
    </Badge>
    </a>
</HoverCardTrigger>
    <HoverCardContent  class="w-80">
        <a :href="getSourceLink(src)" target="_blank">
            <div class="flex space-y-2 justify-between flex-col space-x-4">
                <div class="flex flex-row pl-2 justify-start items-center">
                    <img :src="getSourceIcon(src)" class="h-5 w-5 mr-2" />
                    <h4 class="text-sm font-semibold">
                        {{ src.metadata.title }}
                    </h4>
                </div>
                <div class="space-y-1">
                    <ScrollArea class="h-[200px]">
                        {{ src.page_content }}
                    </ScrollArea>
                </div>
            </div>
        </a>
    </HoverCardContent>
</HoverCard>
</template>
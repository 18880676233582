<script setup>
import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
const router = useRouter();
</script>

<template>
<div class="flex h-[280px] shrink-0 items-center justify-center rounded-md border border-dashed">
    <div class="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="mr-2 lucide lucide-bolt"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/><circle cx="12" cy="12" r="4"/></svg>
      <h3 class="mt-4 text-lg font-semibold">
        Nog geen onderzoek gestart
      </h3>
      <p class="mb-4 mt-2 text-sm text-muted-foreground">
        Je hebt nog geen onderzoek gestart. Start er nu een en krijg snel antwoord.
      </p>
      <Button @click="router.push('/')" class="h-10" variant="default" size="sm">
        <PlusIcon class="h-4 w-4 mr-2" />
        Aan de slag
      </Button>
    </div>
  </div>
</template>
import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useAuth } from '@/auth';
import { api } from '@/plugins/api';



export const useThemeStore = defineStore('theme', () => {
  const auth = useAuth()
  const theme = ref('light')
  const userTheme = computed(() => auth.user?.settings?.theme || 'light')
  const isDarkMode = ref(false);

  function setTheme(newTheme) {
    theme.value = newTheme
    isDarkMode.value = newTheme === 'dark'
  }

  async function updateTheme(newTheme) {
    setTheme(newTheme)
    
    if (auth.user?.id) {
      try {
        await api.put(`/users/${auth.user.id}`, { settings: { theme: newTheme } })
        console.log('Theme updated successfully')
      } catch (error) {
        console.error('Error updating theme:', error)
        setTheme(userTheme.value)
      }
    } else {
      console.log('User not authenticated, theme not saved')
    }
  }

  function toggleDarkMode() {
    updateTheme(isDarkMode.value ? 'light' : 'dark')
  }

  // Watch for changes in userTheme and update accordingly
  watch(userTheme, (newTheme) => {
    setTheme(newTheme)
  }, { immediate: true })

  return {
    isDarkMode,
    toggleDarkMode
  };
});
import { ref } from 'vue';
import { useShepherd } from 'vue-shepherd';
import { useOnboardingStore } from '@/stores/onboarding';
import { useSubscriptionStore } from '@/stores/subscription';

const tourState = ref({
    currentTour: null,
    isActive: false
  });

export function useTour() {
    const tour = useShepherd({
        useModalOverlay: true,
        defaultStepOptions: {
        cancelIcon: {
            enabled: true
        },
        scrollTo: { behaviour: 'smooth', block: 'center'}

        }
    });

    const endTour = () => {
        tour.complete();
        tour.steps = [];
        tourState.value.isActive = false;
        tourState.value.currentTour = null;
        const onboardingStore = useOnboardingStore();
        onboardingStore.completeOnboarding();
      };

    const startTour = (tourName) => {
        if (tours[tourName]) {
          endTour();  
          const subscription = useSubscriptionStore();
          const steps = tours[tourName].filter(step => {
            if (step.title === 'Huidig abonnement') {
              return subscription.plan === 'pro';
            }
            return true;
          });
          tour.addSteps(steps);
          tourState.value.currentTour = tourName;
          tourState.value.isActive = true;
          tour.start();
        }
      };
    
      return {
        startTour,
        endTour,
        tour,
        tourState,
        tours
      };
}

export const tours = {
  home: [
    {
      title: '<div class="flex flex-row items-center justify-center space-x-2"><div class="flex items-center p-2 bg-background border border-border rounded-xl mr-1"><div class="inline-block w-10 h-10 bg-contain bg-no-repeat bg-center" style="background-image: var(--symbol);"></div></div><h4 class="text-lg font-bold">Welkom bij Zeno!</h4></div>',
      text: '<p class="text-md py-2 text-primary/75">Zeno is een AI platform speciaal ontwikkeld voor het ondersteunen van juridische professionals. Stel een vraag in onze zoekmachine en Zeno geeft antwoord met uitgebreide notatie naar de relevante wetgeving en rechtspraak.</p>',
      attachTo: {
        element: () => document.querySelector('.body'),
        on: 'center'
      },
      buttons: [
        {
          text: 'Sla over',
          action() { return this.complete(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Start tour',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
      title: 'Een onderzoek starten',
      text: '<p class="text-md text-primary/75">Een onderzoek start je simpel weg door een casus te beschrijven in de tekstbalk. Voor de beste resultaten is het belangrijk om de casus met zoveel mogelijk relevante context te beschrijven en af te sluiten met een vraag. Op deze manier zijn de bevindingen van Zeno het meest nauwkeurig. <a href="https://www.zeno.law/faq" target="_blank" class="text-secondary cursor-pointer underline">Klik hier</a> om meer te lezen over het optimaal beschrijven van een casus.</p>',
      attachTo: {
        element: () => document.querySelector('.search-bar-ref'),
        on: 'top'
      },
      buttons: [
        {
          text: 'Terug',
          action() { return this.back(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Volgende',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
      title: 'Selecteer een AI-paralegal',
      text: '<p class="text-md text-primary/75">Hier kan je een AI-paralegal selecteren die het onderzoek voor je zal uitvoeren. De zoekbalk staat standaard op Algemeen, wat wil zeggen dat de zoekopdracht alle bronnen raadpleegd waar Zeno toegang tot heeft. <br><br> Wil je meer informatie over de juridische bronnen die Zeno raadpleegd? Klik dan <a href="https://www.zeno.law/faq" target="_blank" class="text-secondary cursor-pointer underline">hier</a>. </p>',
      attachTo: {
        element: () => document.querySelector('.select-paralegal-ref'),
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Terug',
          action() { return this.back(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Volgende',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
        title: 'Benieuwd naar onze AI-paralegals?',
        text: '<p class="text-md text-primary/75">Hier vind je de AI-paralegals. Wij hebben de AI-paralegals in het leven geroepen omdat elk rechtsgebied een vak apart is, met unieke bronnen en relevante context. De antwoorden die een AI-paralegal geeft zijn vaak nauwkeuriger, omdat ze alleen de bronnen benaderen die relevant zijn voor het specifieke rechtsgebied. <br><br> Als Zeno Pro gebruiker is het mogelijk om een eigen AI-paralegal samen te stellen.</p>',
        attachTo: {
          element: () => document.querySelector('.paralegal-sidebar-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ]
      },
    {
        title: 'De onderzoeksbibliotheek',
        text: '<p class="text-md text-primary/75">Hier kun je al je onderzoeken vinden. Handig als je bijvoorbeeld op een later moment je onderzoek wilt hervatten of wilt teruglezen.</p>',
        attachTo: {
          element: () => document.querySelector('.library-sidebar-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ]
      },
      {
        title: 'Huidig abbonement',
        text: '<p class="text-md text-primary/75">Om Zeno Free voor iedereen toegankelijk te houden zit er een limiet aan de hoeveelheid vragen die je per week kunt stellen als gratis gebruiker. Per vraag die je stelt worden er credits gerekend. Als Zeno Free gebruiker, krijg je wekelijks nieuwe credits. Wil je meer credits? Deel Zeno met je netwerk!</p>',
        attachTo: {
          element: () => document.querySelector('.subscription-sidebar-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ],
        when: {
          show: () => {
            const subscription = useSubscriptionStore();
            return subscription.plan === 'pro';
          }
        }
      },
    {
        title: 'Ben je er klaar voor?',
        text: '<p class="text-md text-primary/75">Ga aan de slag en start jouw eerste onderzoek met Zeno. Mocht er nog iets niet duidelijk zijn kan je ons gemakkelijk bereiken door te navigeren naar de "?" knop in de navigatiebalk. Succes!</p>',
        attachTo: {
          element: () => document.querySelector('.body'),
          on: 'center'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Aan de slag',
            action() { return this.complete(); },
            classes: 'custom-tour-main-button'
          }
        ]
    }
  ],
  newParalegal: [
    {
      title: '<div class="flex flex-row items-center justify-center space-x-2"><div class="flex items-center p-2 bg-background border border-border rounded-xl mr-1"><div class="inline-block w-10 h-10 bg-contain bg-no-repeat bg-center" style="background-image: var(--symbol);"></div></div><h4 class="text-lg font-bold">AI-paralegals</h4></div>',
      text: '<p class="text-md text-primary/75">Wij hebben de AI-paralegals functionaliteit in het leven geroepen omdat elk rechtsgebied een vak apart is, met unieke bronnen en context. Als specialist weet jij natuurlijk het beste wat voor jou relevant is. Daarom kun je hier een AI-paralegal geheel naar eigen wens samenstellen. <br><br>Heb je behoefte aan bronnen die wij niet beschikbaar hebben? Geef het door via de "feedback" functie in de navigatiebalk.</p>',
      attachTo: {
        element: () => document.querySelector('.body'),
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Volgende',
          action() { this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
        title: 'Het overzicht van de AI-paralegal',
        text: '<p class="text-md text-primary/75">Hier geef je de paralegal een logische naam en vind je de samenvatting van de bronnen die zijn geselecteerd.</p>',
        attachTo: {
          element: () => document.querySelector('.paralegal_settings_sidebar'),
          on: 'bottom'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { this.next(); },
            classes: 'custom-tour-main-button'
          }
        ]
      },
      {
        title: 'Categorieën van beschikbare bronnen',
        text: '<p class="text-md text-primary/75">Hier kun je selecteren welke bronnen de AI-paralegal zal raadplegen bij een zoekopdracht. Op dit moment hebben wij de gehele Nederlandse wet- en regelgeving en openbare rechtspraak gecategoriseerd beschikbaar.<br><br> Zo kun je bijvoorbeeld een AI-paralegal samenstellen die zich specialiseert op het arbeidsrecht en alleen uitspraken van de Hoge Raad analyseert. </p>',
        attachTo: {
          element: () => document.querySelector('.paralegal_source_categories'),
          on: 'bottom'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() {  this.next(); },
            classes: 'custom-tour-main-button'
          }
        ]
    },
    {
      title: 'Selecteren van bronnen',
      text: '<p class="text-md text-primary/75">Hier selecteer je gemakkelijk de bronnen die de AI-paralegal zal raadplegen bij een zoekopdracht.</p>',
      attachTo: {
        element: () => document.querySelector('.paralegal_source_selection'),
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Terug',
          action() { return this.back(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Volgende',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    }
  ]
};

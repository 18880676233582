<script setup>
import { useSubscriptionStore } from '@/stores/subscription';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import Upgrade from '@/components/Upgrade';
import { Sparkles } from 'lucide-vue-next';
import { PlanCard } from '@/components/settings/';
const subscription = useSubscriptionStore();

const proFeatures = [
  'Alles van basic',
  'Search+',
  'Ongelimiteerde search credits',
  'Custom AI-paralegals',
  'Genereer onderzoeksrapportages'
];
</script>

<template>
    <div>
        <div class="flex flex-col space-y-1 ">
            <div class="flex flex-row items-center">
                <h2 class="mr-2 font-regular text-sm">Huidig abonnement: </h2>
                <Badge variant="outline" :class="[
                    'border px-1 mr-2 font-semibold rounded',
                    subscription.plan === 'pro' ? 'border-secondary bg-secondary/10 text-secondary  ' : 'border-border bg-accent text-muted-foreground'
                ]">
                    <p class="text-xs"> {{ subscription.plan.toUpperCase() }}</p>
                </Badge>
            </div>
            <p class="text-sm text-muted-foreground" v-if="subscription.endDate">Geldig tot: {{ new Date(subscription.endDate).toLocaleDateString() }}</p>
        </div>
        <div class="grid grid-cols-1 py-4 gap-2">
            <PlanCard 
            :class="'bg-backgroundSecondary border-border border'"
            :plan="subscription.plan === 'free' ? 'Ontdek Pro' : 'Pro abonnement'"
            :features="proFeatures"
            :price="'€89,- p/maand'"
            :isFree="subscription.plan === 'free'"
            />
        </div>
        <div class="flex flex-col space-x-2">
            <Upgrade v-if="subscription.plan === 'free'" text="Upgrade naar Pro" :icon="Sparkles" />
            <Button v-if="subscription.plan !== 'free' && subscription.status === 'active'" variant="outline" size="sm" @click="subscription.updateSubscription({ plan: 'free' })">Opzeggen</Button>
            <Button v-if="subscription.plan !== 'free' && subscription.status === 'cancelled'" size="sm" @click="subscription.updateSubscription({ plan: subscription.plan, billing_period: subscription.billingPeriod, quantity: subscription.quantity })"><div class="flex items-center space-x-2"><Sparkles class="w-4 h-4 mr-2" />Heractiveer</div></Button>
        </div>
        
    </div>
</template>
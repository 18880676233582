<script setup>
import { SearchBar } from '@/components/searchbar';
import { useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { ModelRun, Thread } from '@/models'
import { Run } from '@/components/runs'
import { Header } from '@/components/runs'
import { api } from '@/plugins/api'
import { useSocketStore } from '@/stores/socket';
import { onMounted, ref, computed, watch, onUnmounted, inject } from 'vue'
import { usePosthog } from '@/composables/analytics'
const { identifyUser } = usePosthog()
const posthog = inject('posthog')
const route = useRoute();
const { threadSocket } = useSocketStore()
const threadRepo = useAxiosRepo(Thread).setAxios(api)
const threadId = ref(route.params.id)
const thread = computed(() => { return threadRepo.withAll().find(route.params.id) })

async function enterThread(id) {
    threadSocket.emit("enter_thread", { thread_id: id })
    identifyUser()
    posthog.capture('$user_entered_thread', { thread_id: id })
    await useAxiosRepo(ModelRun).setAxios(api).api().get(`/threads/${id}/runs/`)
}

function leaveThread(id) {
    threadSocket.emit("leave_thread", { thread_id: id })
    identifyUser()
    posthog.capture('$user_left_thread', { thread_id: id })
}

watch(route, async (to) => {
    if (to.params.id === threadId.value) return;
    if (threadId.value) leaveThread(threadId.value);
    await enterThread(route.params.id)
})

onMounted(async () => {
    await enterThread(route.params.id)
})

onUnmounted(async () => {
    leaveThread(threadId.value)
})

</script>

<template>
    <div v-if="thread" ref="scrollContainer"
        class="flex flex-col w-screen max-w-5xl min-w-5xl grid grid-cols-8 justify-center mx-auto">
        <div class="col-span-8 justify-center mx-6 py-4">
            <Header :thread="thread" />
            <Run v-for="(run, i) in thread.runs" :key="i" :run="run" :i="i" :threadId="thread.id"
                :isLast="i == thread.runs.length - 1" :parent="scrollContainer" />
            <div v-if="thread.runs.length > 0" class="sticky bottom-4">
                <SearchBar v-model="thread.options" size="sm" ctx="thread" :id="thread.id" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ChevronDown, ChevronUp } from 'lucide-vue-next'
import { ref, watch } from 'vue'
import { Button } from '@/components/ui/button'
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { defineProps, toRefs } from 'vue'

const props = defineProps(['status', 'progress'])
const { status, progress } = toRefs(props)
const isOpen = ref(!(status.value === 'success'))

watch(status, async (s) => {
    if (s === 'success') {
        await new Promise(r => setTimeout(r, 800));
        isOpen.value = false
    }
})

function ifFailed() {
    if (status.value === 'failed') {
        return 'border-2 border-red-500 text-red-500'
    }
}

const stages = [
    {
        title: 'Rechtsvraag en context interpreteren',
        icon: 'bx bx-spreadsheet',
    },
    {
        title: 'Relevante rechtsregels verzamelen',
        icon: 'bx bx-search-alt',
    },
    {
        title: 'Rechtsregels toepassen',
        icon: 'bx bx-brain',
    },
]
</script>

<template>
    <Collapsible v-model:open="isOpen" class="w-full relative">
        <CollapsibleTrigger as-child>
            <div
                :class="`flex cursor-pointer border border-border hover:bg-accent mr-[1px] ml-[2px] flex-col bg-backgroundSecondary relative rounded-lg ${ifFailed()}`">
                <div class="relative p-2">
                    <div v-if="status !== 'failed'"
                        class="flex-row flex text-left text-primary items-center text-xs font-medium">
                        <svg class="mr-3 items-center" width="20" height="20" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.9 0.499976C13.9 0.279062 13.7209 0.0999756 13.5 0.0999756C13.2791 0.0999756 13.1 0.279062 13.1 0.499976V1.09998H12.5C12.2791 1.09998 12.1 1.27906 12.1 1.49998C12.1 1.72089 12.2791 1.89998 12.5 1.89998H13.1V2.49998C13.1 2.72089 13.2791 2.89998 13.5 2.89998C13.7209 2.89998 13.9 2.72089 13.9 2.49998V1.89998H14.5C14.7209 1.89998 14.9 1.72089 14.9 1.49998C14.9 1.27906 14.7209 1.09998 14.5 1.09998H13.9V0.499976ZM11.8536 3.14642C12.0488 3.34168 12.0488 3.65826 11.8536 3.85353L10.8536 4.85353C10.6583 5.04879 10.3417 5.04879 10.1465 4.85353C9.9512 4.65827 9.9512 4.34169 10.1465 4.14642L11.1464 3.14643C11.3417 2.95116 11.6583 2.95116 11.8536 3.14642ZM9.85357 5.14642C10.0488 5.34168 10.0488 5.65827 9.85357 5.85353L2.85355 12.8535C2.65829 13.0488 2.34171 13.0488 2.14645 12.8535C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L9.14646 5.14642C9.34172 4.95116 9.65831 4.95116 9.85357 5.14642ZM13.5 5.09998C13.7209 5.09998 13.9 5.27906 13.9 5.49998V6.09998H14.5C14.7209 6.09998 14.9 6.27906 14.9 6.49998C14.9 6.72089 14.7209 6.89998 14.5 6.89998H13.9V7.49998C13.9 7.72089 13.7209 7.89998 13.5 7.89998C13.2791 7.89998 13.1 7.72089 13.1 7.49998V6.89998H12.5C12.2791 6.89998 12.1 6.72089 12.1 6.49998C12.1 6.27906 12.2791 6.09998 12.5 6.09998H13.1V5.49998C13.1 5.27906 13.2791 5.09998 13.5 5.09998ZM8.90002 0.499976C8.90002 0.279062 8.72093 0.0999756 8.50002 0.0999756C8.2791 0.0999756 8.10002 0.279062 8.10002 0.499976V1.09998H7.50002C7.2791 1.09998 7.10002 1.27906 7.10002 1.49998C7.10002 1.72089 7.2791 1.89998 7.50002 1.89998H8.10002V2.49998C8.10002 2.72089 8.2791 2.89998 8.50002 2.89998C8.72093 2.89998 8.90002 2.72089 8.90002 2.49998V1.89998H9.50002C9.72093 1.89998 9.90002 1.72089 9.90002 1.49998C9.90002 1.27906 9.72093 1.09998 9.50002 1.09998H8.90002V0.499976Z"
                                fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                        </svg>
                        <h4 class="text-lg font-semibold items-center">Voortgang</h4>
                    </div>
                    <div v-else class="flex-row flex text-left items-center text-primary text-xs font-medium">
                        <svg class="mr-3 items-center" width="15" height="15" viewBox="0 0 15 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
                                fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                        </svg>
                        <h4 class="text-lg font-bold items-center">Verwerking mislukt</h4>
                    </div>
                    <Button v-if="progress == 3 && status !== 'failed'" variant="ghost" size="sm"
                        class="absolute top-1 right-1 w-9 p-0 text-primary">
                        <div v-if="!isOpen">
                            <ChevronUp class="h-4 w-4" />
                        </div>
                        <div v-else>
                            <ChevronDown class="h-4 w-4" />
                        </div>
                        <span class="sr-only">Toggle</span>
                    </Button>
                    <div>
                        <CollapsibleContent v-for="(stage, i) in stages" :key="i">
                            <div v-if="progress >= i"
                                class="flex ml-4 justify-left text-primary items-center space-x-4 py-2">
                                <div class="flex justify-center space-x-4">
                                    <i v-if="progress == i" class='bx bx-loader bx-spin'></i><i v-else
                                        :class='stage.icon'></i>
                                </div>
                                <p class="text-sm text-left font-medium leading-none">
                                    {{ stage.title }}
                                </p>

                            </div>
                        </CollapsibleContent>
                    </div>
                </div>
            </div>
        </CollapsibleTrigger>
    </Collapsible>
</template>
<script setup>
import { ref, inject } from 'vue';
import { useAuth } from '@/auth';
// import { storeToRefs } from 'pinia';
import { Button } from '@/components/ui/button';
import PersonalProfileSettings from '@/components/settings/PersonalProfileSettings.vue';
import SubscriptionSettings from '@/components/settings/SubscriptionSettings.vue';
import SearchSettings from '@/components/settings/SearchSettings.vue';
import { AlertDialogContent, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { LogOut } from 'lucide-vue-next';
const auth = useAuth();

import { usePosthog } from '@/composables/analytics';
const { identifyUser, resetUser } = usePosthog();
// const { user } = storeToRefs(auth);

const activeTab = ref('profile');

const settingsTabs = [
    { id: 'profile', label: 'Profiel', component: PersonalProfileSettings },
    { id: 'search', label: 'Search', component: SearchSettings },
    { id: 'subscription', label: 'Abonnement', component: SubscriptionSettings },
];

const posthog = inject('posthog');
const logOut = () => {
    identifyUser();
    posthog.capture('$user_logged_out');
    resetUser();
    auth.logout();
}
</script>

<template>
    <AlertDialogContent class="p-0 min-h-[45vh]">

        <AlertDialogCancel
            class="absolute cursor-pointer top-1 right-1 hover:bg-background hover:text-secondary border-none rounded-full">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                    fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
        </AlertDialogCancel>

        <div class="flex">
            <!-- Sidebar Navigation -->
            <aside class="border-r border-border">
                <nav class="px-3 py-4 flex flex-col h-full">
                    <ul class="space-y-2 flex-grow">
                        <li v-for="tab in settingsTabs" :key="tab.id">
                            <Button variant="ghost" @click="activeTab = tab.id" :class="[
                                'w-full text-sm justify-start',
                                activeTab === tab.id
                                    ? 'bg-accent text-accent-foreground'
                                    : 'hover:bg-accent'
                            ]">
                                {{ tab.label }}
                            </Button>
                        </li>
                    </ul>
                    <div class="mt-auto">
                        <Button variant="outline" class="w-full justify-start space-x-1" @click="logOut">
                            <LogOut class="w-4 h-4" />
                            <p class="text-sm">
                                Log uit
                            </p>
                        </Button>
                    </div>
                </nav>
            </aside>

            <!-- Content Area -->
            <div class="flex-1 min-h-[100px] p-4">
                <div class="text-left" v-if="activeTab === 'profile'">
                    <h2 class="text-1xl font-bold mb-1">Profiel instellingen</h2>
                    <p class="text-sm text-muted-foreground mb-4">Bewerk hier je persoonlijke gegevens.</p>
                    <PersonalProfileSettings v-model="auth.user" />
                </div>

                <div class="text-left" v-else-if="activeTab === 'search'">
                    <h2 class="text-1xl font-bold mb-1">Search instellingen</h2>
                    <p class="text-sm text-muted-foreground mb-4">Pas hier je zoekvoorkeuren aan.</p>
                    <SearchSettings v-model="auth.user" />
                </div>

                <div class="text-left" v-else-if="activeTab === 'subscription'">
                    <h2 class="text-1xl font-bold mb-1">Abonnement</h2>
                    <p class="text-sm text-muted-foreground mb-4">Bewerk hier je abonnement.</p>
                    <SubscriptionSettings v-model="subscription" />
                </div>

            </div>
        </div>

    </AlertDialogContent>
</template>
<script setup>
import { Button } from '@/components/ui/button';
import { Share2 } from 'lucide-vue-next';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogCancel, AlertDialogAction } from '@/components/ui/alert-dialog';
import { toast } from 'vue-sonner';
import { api } from '@/plugins/api'

async function submit() {
  try {
    await api.post('/users/affiliate');
    toast.success('We komen zo snel mogelijk bij je terug!');
  } catch (error) {
    console.error('Affiliate request error:', error);
    console.error('Error response:', error.response);
    toast.error('Er is iets misgegaan bij het doen van een aanvraag');
  }
}

</script>
<template>

<AlertDialog>
    <AlertDialogTrigger class="w-full">
        <Button variant="ghost" size="lg" class="border h-10 w-full border-border">
            <div class="flex flex-row space-x-2 items-center justify-start">
            <Share2 class="w-4 h-4" />
            <p class="text-sm">
                Affiliate programma
            </p>
       

        </div>
    </Button>

    </AlertDialogTrigger>
    <AlertDialogContent>
        
        <div class="absolute top-4 right-4">
            <AlertDialogCancel class="p-1 hover:bg-background hover:text-secondary border-none rounded-full">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
            </AlertDialogCancel>
        </div>
        <AlertDialogHeader>
            <AlertDialogTitle>
                <span>Het Zeno Affiliate programma</span>
            </AlertDialogTitle>
            <AlertDialogDescription class="space-y-4">
                <p class="text-sm">
                    Overtuigd van de toegevoegde waarde van Zeno? Als affiliate kan je een vaste commissie per maand verdienen voor elke Pro gebruiker die zich via jou aanmeldt. 
                    Op deze manier kun je mensen in je netwerk een gunst doen door ze efficiënter te laten werken en tegelijkertijd een passieve inkomenstenbron creeëren. 
                    Word je hier enthousiast over en ben je benieuwd hoe het werkt?
                </p>
                <div class="flex flex-row space-x-2">
                    <AlertDialogAction asChild>
                        <Button @click="submit" variant="default" class="shrink-0">
                        Doe een aanvraag
                    </Button>
                    </AlertDialogAction>
                </div>
            </AlertDialogDescription>
        </AlertDialogHeader>
    </AlertDialogContent>
  </AlertDialog>
</template>
<script setup>
import { onMounted } from "vue";
import Upgrade from "@/components/Upgrade";
import Share from "@/components/sidebar/areas/Share.vue";
import Affiliate from "@/components/sidebar/areas/Affiliate.vue";
import { Progress } from "@/components/ui/progress";
import { UsersRound, Sparkle } from "lucide-vue-next";
import { Button } from "@/components/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { Sparkles } from "lucide-vue-next";
import { useSubscriptionStore } from "@/stores/subscription";

const subscription = useSubscriptionStore();

onMounted(async () => {
    await subscription.fetchUsage();
});
</script>

<template>
    <div v-if="subscription.plan === 'free'"
        class="flex flex-col border-t absolute bottom-0 text-primary/70 px-2 py-4 border-border w-full">
        <div class="flex flex-col border border-border items-end rounded-md p-2 space-y-2">
            <div class="flex flex-row space-x-1 w-full p-2 border-b items-center border-border pb-2 justify-between">
                <p class="text-sm text-primary">Gratis credits</p>
                <Sparkle class="w-4 h-4 text-muted-foreground/50" />
            </div>
            <div class="flex flex-row items-center justify-between w-full pt-2">
                <div class="flex flex-row space-x-1 items-center justify-end w-full">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipContent class="flex w-[250px]">
                                <p class="text-sm">
                                    Per vraag die je stelt worden credits gerekend. Neem Zeno Pro
                                    om onbeperkt te kunnen zoeken.
                                </p>
                            </TooltipContent>
                            <p class="text-xs text-left">
                                {{
                                    Math.max(
                                        subscription.usage?.search?.limit -
                                        subscription.usage?.search?.used,
                                        0
                                    )
                                }} / <span class="text-muted-foreground">{{ subscription.usage?.search?.limit }}</span>
                            </p>
                            <TooltipTrigger><svg 
                                    class="text-muted-foreground"
                                    width="15" height="15" viewBox="0 0 15 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM8.24992 4.49999C8.24992 4.9142 7.91413 5.24999 7.49992 5.24999C7.08571 5.24999 6.74992 4.9142 6.74992 4.49999C6.74992 4.08577 7.08571 3.74999 7.49992 3.74999C7.91413 3.74999 8.24992 4.08577 8.24992 4.49999ZM6.00003 5.99999H6.50003H7.50003C7.77618 5.99999 8.00003 6.22384 8.00003 6.49999V9.99999H8.50003H9.00003V11H8.50003H7.50003H6.50003H6.00003V9.99999H6.50003H7.00003V6.99999H6.50003H6.00003V5.99999Z"
                                        fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                                </svg>
                            </TooltipTrigger>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>

            <Progress :modelValue="subscription.usage?.search?.limit - subscription.usage?.search?.used" :max="subscription.usage?.search?.limit" class="h-[8px]" />
            <div class="flex flex-col space-y-2 pt-2">
                <Upgrade class="w-full" text="Unlimited credits" :size="'lg'" :icon="Sparkles" variant="outline" />
                <Share />
            </div>
        </div>
        
    </div>
    <div v-else class="flex flex-col border-t absolute bottom-0 text-primary/70 px-2 py-4 border-border w-full">
        <div class="flex flex-col border border-border items-end rounded-md p-2">
            <div class="flex flex-row space-x-1 w-full p-2 border-b items-center border-border justify-between">
                <p class="text-sm text-primary">Ontdek meer</p>
                <Sparkle class="w-4 h-4 text-muted-foreground/50" />
            </div>
            <div class="flex flex-col space-y-2 pt-4 w-full">
                <Affiliate /> 
                <a href="https://zeno.law/teams" target="_blank" class="w-full">
                    <Button variant="ghost" size="lg" class="border h-10 w-full border-border">
                        <div class="flex flex-row space-x-2 items-center justify-start">
                            <UsersRound class="w-4 h-4" />
                            <p class="text-sm">Zeno voor Teams</p>
                        </div>
                    </Button>
                </a>
            </div>
        </div>
    </div>
</template>

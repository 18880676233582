<script setup>
import { Button } from '@/components/ui/button'
import { defineProps } from 'vue'
import { PlusIcon, CopyIcon, EyeIcon, EditIcon } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
import { usePosthog } from '@/composables/analytics';
import { inject } from 'vue';

const router = useRouter();
const props = defineProps(['variant', 'mode', 'id', 'text']);
const { identifyUser } = usePosthog();
const posthog = inject('posthog');




const handleClick = () => {
  if (props.mode === 'copy') {
    router.push(`/p/new?copy=${props.id}`);
  } else if (props.mode === 'view' || props.mode === 'edit') {
    router.push(`/p/${props.id}`);
    identifyUser();
    posthog.capture('$user_views_paralegal',{
      paralegal_id: props.id,
      view_paralegal_mode: props.mode
    });
  } else if (props.mode === 'new') {
    router.push('/p/new');
  }
};

const getIcon = () => {
  switch (props.mode) {
    case 'copy': return CopyIcon;
    case 'edit': return EditIcon;
    case 'view': return EyeIcon;
    default: return PlusIcon;
  }
};
</script>

<template>
  <Button :variant="props.variant" @click="handleClick">
    <component :is="getIcon()" :class="[props.text ? 'mr-2' : '', 'h-4 w-4']" />
    {{ props.text }}
  </Button>
</template>
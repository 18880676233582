<script setup>
import { PersonalSettings } from '@/components/settings';
import {
  Dialog,
  DialogTrigger
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button';
import { storeToRefs } from 'pinia';
import { useAuth } from '@/auth';
import { Settings } from 'lucide-vue-next';

const auth = useAuth();
const { user } = storeToRefs(auth);
</script>
<template>


<Dialog>
    <DialogTrigger>
        <Button class="font-bold bg-backgroundSecondary h-10 sm:w-12 w-10" size="sm" variant="outline">
            <Settings size="20" />
            
        </Button>
    </DialogTrigger>
    <PersonalSettings :user="user" />
</Dialog>




</template>

import { posthogPlugin, router, pinia } from '@/plugins';
import './css/tailwind/tailwind.min.css';
import { createApp } from 'vue';
import App from './App.vue';
import './main.css';
import './custom-shepherd.css'
import VueShepherd from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css'

const app = createApp(App);

app.use(pinia);
app.use(posthogPlugin);
app.use(router);
app.use(VueShepherd)
app.mount('#app')
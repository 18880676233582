<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ParalegalForm } from '@/components/paralegals/';
import { toast } from 'vue-sonner';
import { useParalegalManager } from '@/composables/paralegal';

const route = useRoute();
const router = useRouter();
const { getParalegal, saveParalegal, deleteParalegal } = useParalegalManager();

const paralegal = ref(null);

onMounted(async () => {
  const paralegalId = route.params.id;
  paralegal.value = getParalegal(paralegalId);
});

const handleSubmit = async (updatedParalegal) => {
  try {
    await saveParalegal(updatedParalegal);
    router.push({ name: 'paralegals' });
    toast.success('De paralegal is succesvol opgeslagen.');
  } catch (error) {
    console.error('Failed to save paralegal:', error);
    toast.error('Het opslaan van de paralegal is niet gelukt.');
  }
};

const handleDelete = async () => {
  try {
    await deleteParalegal(paralegal.value.id);
    router.push({ name: 'paralegals' });
    toast.success('De paralegal is succesvol verwijderd.');
  } catch (error) {
    console.error('Failed to delete paralegal:', error);
    toast.error('Het verwijderen van de paralegal is niet gelukt.');
  }
};

const handleCopy = async () => {
  router.push(`/p/new?copy=${paralegal.value.id}`);
};
</script>

<template>

  <ParalegalForm v-if="paralegal"  mode="view" :paralegal="paralegal" @submit="handleSubmit" @delete="handleDelete"
    @copy="handleCopy" />

  <div v-else>Loading...</div>
</template>
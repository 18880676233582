<script setup>
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { ref, defineModel, onBeforeMount } from 'vue'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Paralegal } from '@/models'
import { useAxiosRepo } from '@pinia-orm/axios'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Badge } from '@/components/ui/badge'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useAuth } from '@/auth';
import { computed } from 'vue';

const open = ref(false)
const repo = useAxiosRepo(Paralegal);
const paralegalId = defineModel()

const auth = useAuth();
const subscription = computed(() => auth.user.subscription.plan)

onBeforeMount(() => {
  if (!repo.find(paralegalId.value)) {
    paralegalId.value = repo.where("name", "Algemeen").first().id
  }
})

</script>

<template>
  <Popover v-model:open="open">
    <PopoverTrigger as-child>
      <Button variant="outline" role="combobox" :aria-expanded="open" class="w-full min-w-[200px] justify-between">
        <div class="flex space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"
            class="mr-2 lucide lucide-bot-message-square">
            <path d="M12 6V2H8" />
            <path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
            <path d="M2 12h2" />
            <path d="M9 11v2" />
            <path d="M15 11v2" />
            <path d="M20 12h2" />
          </svg>
          {{ paralegalId ? repo.find(paralegalId).name : 'Kies paralegal...' }}
        </div>
        <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0">
      <Command>
        <CommandInput placeholder="Kies paralegal..." />
        <CommandEmpty>Geen paralegal gevonden.</CommandEmpty>
        <CommandList>
          <CommandGroup>
            <template v-for="paralegal in repo.all()" :key="paralegal.id">
              <CommandItem :value="paralegal.name"
                @select="() => { if (!(subscription === 'free' && paralegal.plan === 'pro')) { paralegalId = paralegal.id; open = false } }"
                :disabled="subscription === 'free' && paralegal.plan === 'pro'">
                <div class="flex items-center">
                  <template v-if="subscription === 'free' && paralegal.plan === 'pro'">

                    <Badge variant="outline"
                      class="border border-secondary bg-secondary/10 px-1 mr-2 font-semibold rounded">
                      <p class="text-secondary text-xs">PRO</p>
                    </Badge>
                  </template>
                  <template v-else>
                    <Check :class="cn(
                      'mr-2 h-4 w-4',
                      paralegalId === paralegal.id ? 'opacity-100' : 'opacity-0'
                    )" />
                  </template>
                  <span :class="{ 'opacity-50': subscription === 'free' && paralegal.plan === 'pro' }">
                    {{ paralegal.name }}
                  </span>
                </div>
              </CommandItem>
            </template>
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>
<script setup>
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from '@/components/ui/card';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { defineProps, defineEmits } from 'vue';
import { CircleIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { ManageButton } from '@/components/paralegals';
import { shortenString } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { computed } from 'vue';
import Upgrade from '@/components/Upgrade';

const props = defineProps({
    paralegal: {
        type: Object,
        required: true
    },
    subscription: {
        type: String,
        required: true
    }
})
const emit = defineEmits(['useParalegal']);
const handleUseParalegal = () => {
    emit('useParalegal', props.paralegal.id);
};

const isUsable = computed(() => {
    return props.subscription === 'pro' || props.paralegal.plan !== 'pro';
});

const popoverMode = computed(() => {
    if (!isUsable.value) return 'view';
    return props.paralegal?.custom ? 'edit' : 'view';
});

const popoverText = computed(() => {
    return popoverMode.value === 'edit' ? 'Bewerken' : 'Bekijken';
});


</script>

<template>
<div>
<Card :class="['w-full border-b-0 border-border rounded-b-none bg-backgroundSecondary flex flex-col', { 'opacity-50 border-border/50 border-secondary': !isUsable }]">
    <CardHeader class="flex flex-row items-start gap-4 space-y-0 p-5">
        <div class="space-y-2 text-left flex-grow">
            <CardTitle class="flex flex-row items-center justify-between space-x-2">
                <h1 class="text-lg font-semibold items-center flex">
                    <span class="pr-2">{{ props.paralegal?.name || 'Untitled Paralegal' }}</span>
                    
                </h1>
                <Badge v-if="!isUsable" variant="outline"  class="border border-secondary bg-secondary/10 px-1 mr-2 font-semibold rounded">
                    <p class="text-secondary text-xs">PRO</p>
                </Badge>
                <Popover v-else class="float-right">
                    <PopoverTrigger>
                        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM12.5 8.625C13.1213 8.625 13.625 8.12132 13.625 7.5C13.625 6.87868 13.1213 6.375 12.5 6.375C11.8787 6.375 11.375 6.87868 11.375 7.5C11.375 8.12132 11.8787 8.625 12.5 8.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                    </PopoverTrigger>
                    <PopoverContent class="flex flex-row w-full">
                        <div class="flex flex-row items-center">
                            <ManageButton
                                :mode="popoverMode"
                                :id="props.paralegal?.id"
                                :text="popoverText"
                                :icon="true"
                                variant="ghost"
                                size="sm"
                            />
                        </div>
                    </PopoverContent>
                </Popover>
                
            </CardTitle>
            <CardDescription>
                
                <HoverCard v-if="props.paralegal?.description && props.paralegal.description.length > 99">
                    <HoverCardTrigger>
                        {{ shortenString(props.paralegal.description, 100) }}
                    </HoverCardTrigger>
                    <HoverCardContent>
                        {{ props.paralegal.description }}
                    </HoverCardContent>
                </HoverCard>
                <template v-else>
                    {{ props.paralegal?.description || 'Geen beschrijving' }}
                </template>
            </CardDescription>
        </div>
        
    </CardHeader>
    <CardContent class="flex flex-col space-y-2 p-5">  
        <div class="flex text-muted-foreground text-sm flex-row items-center justify-between w-full">
            <div class="flex items-center">
                <CircleIcon class="h-3 w-3 fill-primary text-primary mr-1" />
                <template v-if="props.paralegal?.threads?.length === 0">
                    <p class="text-xs">Nog niet betrokken bij onderzoeken</p>
                </template>
                <template v-else>
                    <p class="text-xs">Betrokken bij {{ props.paralegal?.threads?.length }} onderzoek{{ props.paralegal?.threads?.length > 1 ? 'en' : '' }}</p>
                </template>
            </div>
        </div>
        <div class="flex text-muted-foreground text-sm flex-row items-center justify-between w-full">
            <div class="flex items-center">
                <CircleIcon class="h-3 w-3 fill-secondary text-secondary mr-1" />
                <p class="text-xs">Laatst bewerkt: {{ props.paralegal?.updated_at ? new Date(props.paralegal?.updated_at).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) : 'Geen datum' }} </p>
            </div>
        </div>
    </CardContent>
    </Card>
    <Button v-if="isUsable"
        class="w-full rounded-b-xl rounded-t-none bg-backgroundSecondary border-t-none"
        :variant="isUsable ? 'outline' : 'secondary'"
        :disabled="!isUsable"
        @click="handleUseParalegal">
        {{ isUsable ? 'Gebruik paralegal' : 'Upgrade naar Pro om te gebruiken' }}
    </Button>
    <Upgrade 
        v-else 
        text="Upgrade naar Pro om te gebruiken" 
        variant="outline" 
        :icon="false" 
        size="sm" 
        :paralegal_card="true"
    />
</div>
</template>
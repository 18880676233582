import { Home, NotFound, Thread, Library, Paralegals, ParalegalManagementView } from '@/views';
import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '@/auth'
import axios from 'axios';
import { inject } from 'vue';


const posthog = inject("posthog")

const guard = async to => {
  const { authGuard } = useAuth()
  await authGuard(to)
}

const login = (to) => {
  const { loginWithRedirect } = useAuth()
  loginWithRedirect(to.query?.redirectPath || '/')
}

const callback = async () => {
  const { user, handleRedirectCallback } = useAuth()
  await handleRedirectCallback()
  posthog.identify(user.id, {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name
  });
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: guard
  },
  {
    path: '/t/:id',
    name: 'thread',
    component: Thread,
    beforeEnter: guard
  },
  {
    path: '/library',
    name: 'library',
    component: Library,
    beforeEnter: guard
  },
  {
    path: '/paralegals',
    name: 'paralegals',
    component: Paralegals,
    beforeEnter: guard
  },
  {
    path: '/p/new',
    name: 'newParalegal',
    component: ParalegalManagementView,
    beforeEnter: guard,
    props: route => ({ copy: route.query.copy })
  },
  {
    path: '/p/:id',
    name: 'viewParalegal',
    component: ParalegalManagementView,
    beforeEnter: guard,
    props: true
  },
  { path: '/:catchAll(.*)', component: NotFound },
  { path: '/auth/login', redirect: login },
  { path: '/auth/callback',
    name: 'callback',
    beforeEnter: async (next) => {
      await callback();
      next('/');
    }
  },
  {
    name: 'checkout', path: '/orders/checkout', beforeEnter: async (to, from, next) => {
      try {
        const { useAuth } = require('@/auth');
        const { getAccessToken } = useAuth()
        const url = new URL(`${process.env.VUE_APP_API_URL}/orders/checkout`)
        const token = await getAccessToken();
        const response = await axios.post(url.toString(), to.query, { headers: { Authorization: `Bearer ${token}` } })
        if (response.data && response.data?.id === undefined) window.location.href = response.data;
        next('/');
      } catch (error) {
        console.error(error)
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
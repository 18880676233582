import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread } from '@/models';
import { api } from '@/plugins/api';
import { ref } from 'vue';

const repo = useAxiosRepo(Thread).setAxios(api);
const editingId = ref(null);

export async function deleteThread(id, route, router) {
    if (route.params?.id === id) {
      await router.push({ name: 'home' });
    }
    await repo.api().delete(`/threads/${id}`, { 'delete': id });
  }

export async function updateThreadName(thread) {
    await repo.api().put(`/threads/${thread.id}`, thread);
    editingId.value = null;
  }
  
export function startEditing(id) {
    editingId.value = id;
  }

export { editingId };
<script setup>
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { defineProps, defineEmits, computed } from 'vue';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  cols: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['update:modelValue']);

const selectedValues = computed({
  get: () => props.modelValue,
  set: (newValue) => emit('update:modelValue', newValue)
});
</script>

<template>
  <FormField :name="props.label">
    <FormItem>
      <FormLabel>{{ props.title }}</FormLabel>
      <FormControl>
        <ToggleGroup v-model="selectedValues" type="multiple" variant="outline" :disabled="props.disabled">
          <div class="grid w-full gap-2" :class="`grid-cols-${props.cols}`">
            <ToggleGroupItem class="flex flex-grow flex-row space-x-2 items-center justify-between" v-for="option in props.options" :key="option" :value="option" :aria-label="`Toggle ${option}`">
              

              <HoverCard asChild>
                <HoverCardTrigger asChild>
                  <p class="text-sm font-regular text-wrap truncate" :class="{ 'text-muted-foreground': !selectedValues.includes(option) }">
                    {{ option }}
                  </p>
                </HoverCardTrigger>
                <HoverCardContent class="text-left w-50">
                  <p class="text-sm">{{ option }}</p>
                </HoverCardContent>
              </HoverCard>
              <div class="flex flex-row items-center justify-center">
                  
                <svg v-if="selectedValues.includes(option)" key="checked" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide text-secondary lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                <svg v-else key="unchecked" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book text-muted-foreground"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20"/></svg>

              </div>
            </ToggleGroupItem>
          </div>
        </ToggleGroup>
      </FormControl>
    </FormItem>
  </FormField>
</template>
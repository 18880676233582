<script setup>
import { watch, onMounted, defineProps, toRefs, ref, inject } from 'vue';
import { Button } from '@/components/ui/button';
import { useSearch } from '@/composables';
import Upgrade from '@/components/Upgrade';
import { usePosthog } from '@/composables/analytics';
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const open = ref(false)
const props = defineProps(['ctx', 'id']);
const { ctx, id } = toRefs(props);
const { text, isLocked, isDisabled, sendMessage } = useSearch(ctx.value, id.value)

function format() {
    if (text.value.endsWith(' ') && text.value.length > 1) {
        return;
    }
    text.value = text.value.replace(/\n/g, '');
    text.value = text.value.trim();
}

function resize() {
    const element = document.getElementById('searchbar-small-textarea');
    if (!element) return;
    element.style.height = '25px';
    if (text.value != '') element.style.height = element.scrollHeight + 'px';
}

watch(text, () => {
    format();
    resize();
});

onMounted(async () => {
    resize();
    let element = null;
    while (!element) {
        element = document.getElementById('searchbar-small-textarea');
        await new Promise((resolve) => setTimeout(resolve, 300));
    }
    element?.focus();
});

async function send() {
    try {
        await sendMessage()
    } catch (e) {
        identifyUser()
        posthog.capture('$user_out_of_credits', { thread_id: id?.value })
        open.value = true;
    }
}

function handleEnter(e) {
    if (!e.shiftKey) {
        e.preventDefault();
        send();
    }

}

</script>

<template>
    <div class="bg-backgroundSecondary/90 dark:bg-backgroundSecondary border flex items-center justify-center rounded-lg p-1">
        <div class="py-2 w-full flex flex-row border items-center  dark:bg-background bg-white border-dark-400 rounded-lg"
            style="width: 101%;">
            <div class="mx-4 h-full flex-grow py-1">
                <textarea
                    v-model="text"
                    wrap="soft" 
                    class="text-md w-full overflow-y-scroll max-h-[100px] dark:bg-background bg-white outline-none caret-secondary"
                    :class="{ 'text-muted-foreground placeholder-muted-foreground/40': isDisabled, 'text-primary/80': !isDisabled }"
                    id="searchbar-small-textarea" type="text" 
                    :placeholder="isDisabled ? 'Sla op om de AI-paralegal te gebruiken' : (ctx === 'paralegal' ? 'Stel een vraag aan de AI-paralegal' : (!isLocked ? 'Stel een vervolgvraag' : 'Zeno is aan het typen...'))"
                    :disabled="isLocked || isDisabled"
                    @input="format"
                    @keydown.enter="handleEnter"
                    @keyup.enter="handleEnter">
                </textarea>
            </div>
            <div v-if="isDisabled" class="mx-2">
                <Button class="bg-primary/60 hover:bg-primary/60">
                    <i class='bx bxs-send'></i>
                </Button>

            </div>
            <div v-else class="mx-2">
                <Button v-if="!isLocked" @click="send">
                    <i class='bx bxs-send'></i>
                </Button>
                <Button v-else>
                    <i class='bx bx-loader-alt bx-spin'></i>
                </Button>
            </div>
        </div>
    </div>
    <Upgrade :share="true" v-model="open" :no_button="true"/>
</template>

<style scoped>
#searchbar-small-textarea {
    resize: none;
    vertical-align: middle;
    height: auto;
}
</style>
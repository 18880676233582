<script setup>
import { useRouter } from 'vue-router';
import { ChevronRight } from 'lucide-vue-next';
import { ParalegalItem, EmptyPlaceholder, ManageButton } from '@/components/paralegals';
import { Paralegal } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useAuth } from '@/auth';
import { computed } from 'vue';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const router = useRouter();
const repo = useAxiosRepo(Paralegal);

const useParalegal = (paralegalId) => {
  router.push({ path: '/', query: { paralegalId } });
};

const auth = useAuth();
const subscription = computed(() => auth.user.subscription.plan)

</script>

<template>
  <div class="mx-6 my-4">
    <div class="mb-4">
        <Breadcrumb>
            <BreadcrumbList>
            <BreadcrumbItem>
                <BreadcrumbLink class="cursor-pointer hover:text-secondary text-muted-foreground/80" @click="router.push('/')">
                Home
                </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator>
                <ChevronRight class="h-4 w-4" />
            </BreadcrumbSeparator>
            <BreadcrumbItem>
                <BreadcrumbLink class="text-primary/80">
                Paralegals
                </BreadcrumbLink>
            </BreadcrumbItem>
            </BreadcrumbList>
        </Breadcrumb>
    </div>
    <div class="flex flex-row justify-between items-center">
    
        <h4 class="text-left text-xl font-semibold justify-start flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="mr-2 lucide lucide-bot-message-square"><path d="M12 6V2H8"/><path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"/><path d="M2 12h2"/><path d="M9 11v2"/><path d="M15 11v2"/><path d="M20 12h2"/></svg>
            Paralegals
        </h4>
    
    <ManageButton 
      mode="new" 
      text="" 
      :icon="true" 
      class="text-sm bg-backgroundSecondary" 
      variant="outline"
      :disabled="subscription === 'free'"
      :class="{ 'border-secondary dark:text-secondary': subscription === 'free' }"
    />
    
    </div>
    <div v-if="repo.query().withAll().where('custom', true).get().length > 0">
        <div class="grid my-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <ParalegalItem 
              v-for="paralegal in repo.query().withAll().where('custom', true).get()" 
              :key="paralegal.id" 
              :paralegal="paralegal" 
              :subscription="subscription"
              @use-paralegal="useParalegal"
            />
            <!--- <Card @click="router.push('/a/new')" class="w-full shadow bg-background hover:bg-accent cursor-pointer hover:text-primary text-muted-foreground h-full transition-colors duration-300 border border-dashed border-border flex items-center justify-center">
                <PlusIcon class="h-6 w-6" />
            </Card> -->
        </div>
    </div>
    <EmptyPlaceholder :subscription="subscription" class="my-4" v-else />
    <h4 class="scroll-m-20 text-left text-xl font-semibold tracking-tigh justify-start py-2 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="mr-2 lucide lucide-layout-grid"><rect width="7" height="7" x="3" y="3" rx="1"/><rect width="7" height="7" x="14" y="3" rx="1"/><rect width="7" height="7" x="14" y="14" rx="1"/><rect width="7" height="7" x="3" y="14" rx="1"/></svg>
        Standaard paralegals
    </h4>
    <div v-if="repo.query().withAll().where('custom', false).all().length > 0">
        <div class="grid my-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <ParalegalItem 
              v-for="paralegal in repo.query().withAll().where('custom', false).get()" 
              :key="paralegal.id" 
              :paralegal="paralegal"
              :subscription="subscription"
              @use-paralegal="useParalegal"
            />
        </div>
    </div>
    <div v-else class="text-muted-foreground">
        Geen paralegals beschikbaar.
    </div>

</div>

</template>
import posthog from "posthog-js";

export default {
    install(app) {
        posthog.init(
            process.env.VUE_APP_POSTHOG_TOKEN,
            { api_host: 'https://eu.i.posthog.com' }
        );
        app.config.globalProperties.$posthog = posthog;
        app.provide("posthog", posthog);
    },
};
<script setup>
import Suggestions from '@/components/runs/Suggestions'
import { SourceList } from '@/components/sources'
import Progress from '@/components/runs/Progress'
import Title from '@/components/runs/Title'
import Answer from '@/components/runs/Answer'
import { computed, defineProps, watch, defineEmits, ref, onMounted, nextTick, toRefs } from 'vue'
import { Separator } from '@/components/ui/separator'
import { useElementSize } from '@vueuse/core';
import { Gavel, Scale } from 'lucide-vue-next'
import { useSearch } from '@/composables'

const props = defineProps(['i', 'run', 'threadId', 'isLast'])
const { i, run, threadId, isLast } = toRefs(props)
const emits = defineEmits(['changing', 'sizing']);
const el = ref(null);
const { height } = useElementSize(el);
const { text, sendMessage } = useSearch('thread', threadId.value)

const progress = computed(() => {
    if (run.value?.suggested_follow_ups?.length > 0) return 3;
    if (run.value?.answer) return 2;
    if (run.value?.sources?.cases?.length > 0 || run.value.sources?.consolidations?.length > 0) return 1;
    return 0;
})

onMounted(() => {
    emits('sizing', i, height.value)
})

watch(run, () => {
    emits('changing')
    emits('sizing', i, height.value)
})

const cls = computed(() => {
    let c = ''
    if (isLast.value) c += ' min-h-screen bg-background mb-5'
    return c
})

const scrollContainer = ref(null);

// const scrollToBottom = () => {
//   nextTick(() => {
//     if (parent.value && isLast.value && progress.value >= 3) {
//       parent.value.scrollIntoView({ behavior: 'smooth', block: 'end'});
//     }
//   });
// };
const scrollIntoView = () => {
    nextTick(() => {
        if (scrollContainer.value && isLast && progress.value <= 3) {
            scrollContainer.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    });
};

//watch(() => inquiry.value.response, scrollToBottom, { deep: true });
// watch(() => progress.value, () => {
//     if (progress.value == 4) {
//         scrollToBottom();
//     }
// }, { deep: true });
onMounted(
    () => {
        if (progress.value <= 2) {
            scrollIntoView();
        }
        // } else if (progress.value == 3) {
        //     scrollToBottom();
        // }
    }
)
</script>

<template>
    <div ref="scrollContainer bg-background" :class="cls">
        <Separator v-if="i > 0" class="mt-4" />
        <Title class="text-primary title-ref" :question="run?.query" />
        <Progress class="mb-5" :status="run?.status" :progress="progress" />
        <SourceList :icon="Scale" class="law-source-ref mb-5" :show="progress > 1" title="Wet- en regelgeving"
            type="Consolidation" :sources="run.sources?.consolidations" />
        <SourceList :icon="Gavel" class="case-source-ref mb-5" :show="progress > 1" title="Uitspraken" type="Case"
            :sources="run.sources?.cases" />
        <Answer class="my-5" :show="progress >= 2" :answer="run?.answer" :sources="run?.sources" />
        <Suggestions v-if="isLast && (run?.status === 'success')" v-model="text" :sendMessage="sendMessage"
            :suggestions="run?.suggested_follow_ups" />
    </div>
</template>

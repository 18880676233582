<script setup>
import { defineProps } from 'vue';
import { Toaster as Sonner } from 'vue-sonner';
const props = defineProps([
  'invert',
  'position',
  'hotkey',
  'richColors',
  'expand',
  'duration',
  'gap',
  'visibleToasts',
  'closeButton',
  'toastOptions',
  'class',
  'style',
  'offset',
  'dir',
  'icons',
  'containerAriaLabel',
  'pauseWhenPageIsHidden',
  'cn',
])
</script>

<template>
  <Sonner
    class="toaster group"
    v-bind="props"
    :toast-options="{
      classes: {
        toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
        description: 'group-[.toast]:text-muted-foreground',
        actionButton:
          'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
        cancelButton:
          'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
      },
    }"
  />
</template>

<script setup>
import { ref } from 'vue';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Share2 } from 'lucide-vue-next';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogCancel, AlertDialogAction } from '@/components/ui/alert-dialog';
import { toast } from 'vue-sonner';
import { api } from '@/plugins/api'

const email = ref('');

async function submit() {
  const val = email.value.trim();
  if (!val) {
    toast.error('Vul een emailadres in om een uitnodiging te versturen');
    return;
  }
  try {
    await api.post('/users/invite', val)
    email.value = '';
    toast.success('Uitnodiging verstuurd naar ' + val);
  } catch (error) {
    toast.error('Er is iets misgegaan bij het versturen van de uitnodiging');
  }
}

</script>
<template>

<AlertDialog>
    <AlertDialogTrigger class="w-full">
        <Button variant="ghost" size="lg" class="border h-10 w-full border-border">
            <div class="flex flex-row space-x-2 items-center justify-start">
            <Share2 class="w-3 h-3 sm:w-4 sm:h-4" />
            <p class="text-xs sm:text-sm">
                Deel Zeno!
            </p>
       

        </div>
    </Button>

    </AlertDialogTrigger>
    <AlertDialogContent>
        
        <div class="absolute top-4 right-4">
            <AlertDialogCancel class="p-1 hover:bg-background hover:text-secondary border-none rounded-full">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
            </AlertDialogCancel>
        </div>
        <AlertDialogHeader>
            <AlertDialogTitle>
                <span>Verdien extra credits!</span>
            </AlertDialogTitle>
            <AlertDialogDescription class="space-y-4">
                <p class="text-sm">
                    Door Zeno te delen krijg je extra credits toegewezen* aan je account. 
                </p>
                <div class="flex flex-row space-x-2">
                    <Input v-model="email" placeholder="Email" />
                    <AlertDialogAction asChild>
                        <Button @click="submit" variant="default" class="shrink-0">
                        Stuur uitnodiging
                    </Button>
                    </AlertDialogAction>
                </div>
                <div class="flex flex-row space-x-2">
                    <p class="text-xs text-muted-foreground">
                        * De credits worden alleen toegekend wanneer de uitgenodigde via jouw link een account aanmaakt.
                    </p>
                </div>
            </AlertDialogDescription>
        </AlertDialogHeader>
    </AlertDialogContent>
  </AlertDialog>
</template>
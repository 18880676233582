<script setup>
import { defineProps, toRefs } from 'vue'
import SearchBarSmall from '@/components/searchbar/SearchBarSmall'
import SearchBarLarge from '@/components/searchbar/SearchBarLarge'
const props = defineProps(['size', 'ctx', 'id'])
const { size, ctx, id } = toRefs(props)
</script>

<template>
    <div>
        <SearchBarSmall v-if="size === 'sm'" :ctx="ctx" :id="id" />
        <SearchBarLarge v-if="size === 'lg'" :ctx="ctx" :id="id" />
    </div>
</template>
<script setup>
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible'
import { ChevronDown, ChevronUp } from 'lucide-vue-next'
import { Source } from '@/components/sources'
import { Skeleton } from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'
import { defineProps, ref, toRefs } from 'vue'
const props = defineProps(['icon', 'title', 'sources', 'show'])
const { icon, title, sources, show } = toRefs(props)
const isOpen = ref(false)
</script>

<template>
    <div>
        <Collapsible v-model:open="isOpen">
            <div class="flex flex-row justify-between">
                <h4 class="scroll-m-20 text-left text-xl font-semibold tracking-tigh justify-start flex items-center mb-4">
                    <icon class="mr-2 mb-1" :size="22" />
                    {{ title }}
                </h4>
                <CollapsibleTrigger v-if="sources?.length > 5" as-child>
                    <Button variant="ghost" size="sm">
                        <p class="text-sm mr-1">Toon alles</p>
                        <div v-if="isOpen == false">
                            <ChevronUp class="h-4 w-4" />
                        </div>
                        <div v-else>
                            <ChevronDown class="h-4 w-4" />
                        </div>
                        <span class="sr-only">Toggle</span>
                    </Button>
                </CollapsibleTrigger>
            </div>
            <div v-if="show">
                <p v-if="!sources?.length" class="text-left">
                    Geen relevante {{ title?.toLowerCase() }} gevonden.
                </p>
                <div class="grid grid-cols-2 lg:grid-cols-5 gap-1 mb-1 pr-1">
                    <Source v-for="(src, i) in sources?.slice(0, 5)" :key="src.id" :src="src" :i="i" />
                </div>
                <CollapsibleContent v-if="sources?.length > 5">
                    <div class="grid grid-cols-2 lg:grid-cols-5 gap-1 mb-1 pr-1">
                        <Source v-for="(src, i) in sources?.slice(5)" :key="src.id" :src="src" :i="i + 5" />
                    </div>
                </CollapsibleContent>
            </div>
            <div v-else class="grid grid-cols-2 lg:grid-cols-4 gap-1 mb-1">
                <Skeleton class="h-[100px] w-full mx-1 rounded-lg" />
                <Skeleton class="h-[100px] w-full mx-1 rounded-lg" />
                <Skeleton class="h-[100px] w-full mx-1 rounded-lg" />
                <Skeleton class="h-[100px] w-full mx-1 rounded-lg" />
            </div>
        </Collapsible>
    </div>
</template>
<script setup>
import { HoverCard, HoverCardTrigger } from '@/components/ui/hover-card'
import { getSourceLink, getSourceSubTitle, getSourceTitle } from '@/lib/sources'
import { SourceReference } from '@/components/sources'
import { defineProps, toRefs } from 'vue'
import { shortenString } from '@/lib/utils'
const props = defineProps(['i', 'src'])
const { i, src } = toRefs(props)

function openInNewTab(url) {
    const win = window.open(url, '_blank')
    win.focus()
}
</script>

<template>
    <HoverCard>
        <HoverCardTrigger as-child>
            <div @click="openInNewTab(getSourceLink(src))"
                class="flex flex-col border border-border cursor-pointer hover:bg-accent mx-1 flex-col relative bg-backgroundSecondary rounded-lg h-[110px] xl:h-[130px] w-full overflow-auto">
                <div class="flex p-2 items-center justify-left">
                    <div class="flex flex-row text-left items-center">
                        <h4 class="font-bold text-xs"> {{ shortenString(getSourceTitle(src), 50) }} </h4>
                    </div>
                </div>
                <div class="px-2 flex text-left w-full overflow-auto text-wrap  text-xs">
                    <p class="font-medium text-primary/70 break-words w-full"> {{ shortenString(getSourceSubTitle(src), 60) }}
                    </p>
                </div>
                <div class="absolute bottom-1 left-1 pt-2 justify-start">
                    <SourceReference :src="src" :i="i" />
                </div>
            </div>
        </HoverCardTrigger>
    </HoverCard>
</template>
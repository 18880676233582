import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL });
api.interceptors.request.use(async function (config) {
    const { useAuth } = require('@/auth');
    const { getAccessToken } = useAuth();
    const token = await getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export { api };
// useSearch.js
import { useAxiosRepo } from '@pinia-orm/axios';
import { ref, onMounted, computed } from 'vue';
import { Thread, Paralegal } from '@/models';
import { useRouter } from 'vue-router';
import addFormats from 'ajv-formats';
import { api } from '@/plugins/api';
import { useSubscriptionStore } from '@/stores/subscription';
import Ajv from 'ajv';
import { useSocketStore } from '@/stores/socket';

const ajv = new Ajv({ useDefaults: true, removeAdditional: true });
addFormats(ajv);

/*
This composable is used to send messages to the server and to handle the search bar and the responses.
It expects a ctx and an id to be passed in.

ctx can be 'thread', 'home' or 'paralegal'
if ctx is 'thread', the id is the id of the thread
if ctx is 'home', the id is the id of the agent
if ctx is 'paralegals', the id is the id of the agent
*/
export function useSearch(ctx, id) {
    const socket = useSocketStore()
    const router = useRouter()
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const paralegalRepo = useAxiosRepo(Paralegal).setAxios(api)
    const paralegalId = ref(null);
    if (ctx === 'new_paralegal') {
        id=paralegalRepo.first().id;
        paralegalId.value = id;
    } else if (ctx === 'thread') {
        paralegalId.value = threadRepo.find(id).paralegal_id;
    } else {
        if (!paralegalRepo.find(id)) {
            paralegalId.value = paralegalRepo.where("name", "Algemeen").first().id
        } else {
            paralegalId.value = id;
        }
    }
    const subscription = useSubscriptionStore()
    const text = ref('')
    const schema = ref(ctx === 'thread' ? threadRepo.withAllRecursive().find(id).paralegal.schema : paralegalRepo.withAll().find(paralegalId.value).schema)
    const settings = ref({})
    ajv.validate(schema.value, settings.value)

    const isLocked = computed(() => {
        if (ctx !== 'thread') return false
        return threadRepo.withAllRecursive().find(id).isLocked
    })

    const isDisabled = computed(() => ctx === 'new_paralegal')

    async function connect() {
        if (socket.threadSocket !== null) return
        await socket.threadSocket.connect()
    }

    async function createThread(msg) {
        const response = await threadRepo.api().post('/threads/', { paralegal_id: paralegalId.value, name: msg });
        return response.entities[0].id
    }

    async function sendMessage() {
        if (await subscription.isOutOfCreditsFor('search')) throw Error('Out of credits')
        if (socket.threadSocket === null) throw new Error('Socket not connected');
        const msg = text.value.trim();
        if (!msg) return;
        if (!ajv.validate(schema.value, settings.value)) return;
        let thread_id = id
        if (ctx !== 'thread') {
            thread_id = await createThread(msg)
            router.push({ name: 'thread', params: { id: thread_id } });
        }
        socket.threadSocket.emit('run', {
            thread_id: thread_id,
            query: msg,
            settings: settings.value,
        })
        text.value = ''
    }

    onMounted(connect)

    return {
        paralegalId,
        text,
        settings,
        schema,
        isLocked,
        isDisabled,
        sendMessage
    }
}
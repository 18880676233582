<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { Button } from '@/components/ui/button';

const props = defineProps({
  routeName: String,
  buttonText: String,
});

const router = useRouter();
</script>

<template>
  <div class="flex flex-row space-x-2 items-center justify-start pt-1 px-2">
    <Button @click="router.push({ name: props.routeName })" variant="ghost" class="w-full justify-start space-x-2 px-3">
        <slot name="icon"></slot>
        <h2 class="text-left text-lg font-semibold tracking-tight">
            {{ props.buttonText }}
        </h2>
    </Button>
  </div>
</template>
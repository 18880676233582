// useSearch.js
import { useAxiosRepo } from '@pinia-orm/axios';
import { ref, onMounted } from 'vue';
import { Thread, Paralegal } from '@/models';
import { api } from '@/plugins/api';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
import { useSocketStore } from '@/stores/socket';

export function useData() {
    const isLoading = ref(true)

    onMounted(async () => {
        const repo = useAxiosRepo(Paralegal);
        const auth = useAuth();
        const { isAuthenticated } = storeToRefs(auth);
        if (repo.all().length === 0) {
            await new Promise(resolve => {
                const checkAuthentication = () => {
                    if (isAuthenticated.value) {
                        resolve();
                    } else {
                        setTimeout(checkAuthentication, 100);
                    }
                };
                checkAuthentication();
            });
            await useAxiosRepo(Paralegal).setAxios(api).api().get('/paralegals/', { params: { limit: 15, offset: 0 } });
            await useAxiosRepo(Thread).setAxios(api).api().get('/threads/', { params: { limit: 15, offset: 0 } });
        }
        const socket = useSocketStore()
        await socket.connect()
        isLoading.value = false;
    })

    return {
        isLoading
    }
}
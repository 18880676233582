import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { ref } from 'vue';
import axios from 'axios';

export const useAuth = defineStore('auth', () => {
    const user = ref(null)
    const organization = ref(null)
    const role = ref(null)
    const isAuthenticated = ref(false)
    const isLoading = ref(false)
    const error = ref(null)

    function set(data) {
        user.value = data.user
        organization.value = data.organization
        role.value = data.role
        Cookies.set("access-token", data.accessToken)
        Cookies.set("refresh-token", data.refreshToken)
        isAuthenticated.value = true
    }

    function clear() {
        user.value = null
        organization.value = null
        role.value = null
        Cookies.remove("access-token")
        Cookies.remove("refresh-token")
    }

    function loginWithRedirect(redirectPath = "/") {
        const route = useRoute();
        const loginUrl = new URL(`${process.env.VUE_APP_API_URL}/auth/login`)
        if (route?.params.state) {
            loginUrl.searchParams.append('state', route.params.state)
        } else {
            const state = btoa(JSON.stringify({ redirectPath: redirectPath }))
            loginUrl.searchParams.append('state', state)
        }
        window.location.href = loginUrl.toString();
    }

    async function handleRedirectCallback() {
        const route = new URL(window.location.href)
        isLoading.value = true
        try {
            if (!route.searchParams.get("state")) throw Error("Invalid callback signature");
            const data = JSON.parse(atob(route.searchParams.get("state")))
            set(data)
            setTimeout(refreshAccessToken, getTokenExpiration(data.accessToken) - 30000)
            isAuthenticated.value = true
            if (route.fullPath != data.redirectPath) window.location.href = data.redirectPath;
        } catch (err) {
            error.value = err
        } finally {
            isLoading.value = false
        }
    }

    async function logout() {
        isLoading.value = true
        try {
            const token = await getAccessToken();
            const response = await axios.get('/auth/logout', {
                headers: { Authorization: `Bearer ${token}` }
            })
            clear()
            isAuthenticated.value = false
            window.location.href = response.data
        } catch (err) {
            error.value = err
        } finally {
            isLoading.value = false
        }
    }

    async function refreshAccessToken() {
        try {
            const refreshToken = Cookies.get('refresh-token')
            if (!refreshToken) throw Error('Missing refresh token')
            const response = await axios.post('/auth/refresh', refreshToken)
            set({...response.data, user: user.value, organization: organization.value, role: role.value})
            return response.data.accessToken
        } catch (err) {
            const accessToken = Cookies.get('access-token')
            const exp = getTokenExpiration(accessToken)
            if (exp > 0) {
                return setTimeout(refreshAccessToken, Math.max(Math.ceil(getTokenExpiration(accessToken) / 6), 1000))
            } else {
                error.value = err
                clear()
                isAuthenticated.value = false
                const route = useRoute();
                loginWithRedirect(route?.fullPath || "/")
            }
        }
    }

    function getTokenExpiration(token) {
        if (!token) return 0;
        const payload = JSON.parse(atob(token.split(".")[1]))
        return (payload.exp * 1000) - Date.now()
    }

    async function getAccessToken() {
        let accessToken = Cookies.get('access-token')
        const exp = getTokenExpiration(accessToken)
        if (!accessToken || exp <= 0) loginWithRedirect();
        if (exp <= 60000) {
            accessToken = await refreshAccessToken()
            setTimeout(refreshAccessToken, getTokenExpiration(accessToken) - 30000)
        }
        return accessToken
    }

    async function checkAuth() {
        // Check if the user is authenticated
        // This might involve verifying the token with your backend
        isLoading.value = true
        try {
            const token = await getAccessToken()
            const response = await axios.get('/auth/check', {
                headers: { Authorization: `Bearer ${token}` }
            })
            response.data.accessToken = Cookies.get('access-token')
            response.data.refreshToken = Cookies.get('refresh-token')
            set(response.data)
            isAuthenticated.value = true
        } catch (err) {
            clear()
            isAuthenticated.value = false
            error.value = error
        } finally {
            isLoading.value = false
        }
    }

    async function authGuard(to) {
        if (!isAuthenticated.value && !isLoading.value) {
            await checkAuth()
            if (!isAuthenticated.value && !isLoading.value) {
                loginWithRedirect(to.fullPath)
            }
        }
        return to
    }

    return {
        user,
        organization,
        role,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        handleRedirectCallback,
        getAccessToken,
        checkAuth,
        authGuard,
    }
});